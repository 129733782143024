import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "./menuItem";
import { toggleLoginModal } from "../../state/modal";
import { useAppDispatch } from "../../state/hooks";

const Header = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const toggleMenu = () => setMenuVisible((prev) => !prev);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menuItems = [
    {
      width: 324,
      to: "/",
      label: "Socials",
      subMenu: [
        {
          label: "Facebook",
          imgName: "facebook.png",
          to: "https://www.facebook.com/iPlayMore",
          size: 22,
        },
        {
          label: "Instagram",
          imgName: "instagram.png",
          to: "https://www.instagram.com/thunderthronewars/?theme=dark",
          size: 22,
        },
        {
          label: "LinkedIn",
          imgName: "linkedin.png",
          to: "https://www.linkedin.com/company/iplaymore/posts/?feedView=all",
          size: 22,
        },
        {
          label: "Youtube",
          imgName: "youtube.png",
          to: "https://www.youtube.com/@iPlayMore",
          size: 22,
        },
        // { label: "Discord", imgName: "discord.png", to: "/", size: 22 },
        {
          label: "X/Twitter",
          imgName: "twitter.png",
          to: "https://x.com/iplaymore",
          size: 22,
        },
        {
          label: "Twitch",
          imgName: "twitch.png",
          to: "https://www.twitch.tv/iPlayTHUNDER",
          size: 22,
        },
        {
          label: "Artstation",
          imgName: "artstation.png",
          to: "https://www.artstation.com/iplaymore",
          size: 22,
        },
      ],
    },
    {
      width: 202,
      to: "/",
      label: "THUNDER©",
      subMenu: [
        { label: "Novel", imgName: "novel.png", to: "/novel", size: 25 },
        {
          label: "Art & Music",
          imgName: "music.png",
          to: "/art-music",
          size: 29,
        },
      ],
    },
    {
      width: 202,
      to: "/",
      label: "Download",
      subMenu: [
        {
          label: "Prologue",
          imgName: "thunder-game.png",
          to: "/prologue",
          size: 35,
        },
        {
          label: "Twitch Tool",
          imgName: "twitch2.png",
          to: "/twitch-tool",
          size: 27,
        },
      ],
    },
    {
      width: 244,
      to: "/",
      label: "iPlayMore",
      subMenu: [
        {
          label: "Who we are",
          imgName: "iplaymore.png",
          to: "/iplaymore",
          size: 34,
        },
        {
          label: "Testimonials",
          imgName: "teammates.png",
          to: "/testimonials",
          size: 34,
        },
        {
          label: "Update Logs",
          imgName: "update-arrow.png",
          to: "/update-logs",
          size: 24,
        },
        // { label: "Video Playlist", imgName: "polygon.png", to: "/videos", size: 24 },
      ],
    },
  ];

  return (
    <>
      <div
        className={` shadow-header sticky top-0 z-[1505] flex w-full h-[34px] md:h-[51px] lg:h-[51px] bg-[#090909] items-center px-1 py-4 md:px-10 justify-between -mt-1`}
      >
        <div className="flex items-center gap-1 md:gap-4">
          <Link to="/iplaymore">
            <img
              alt="header-logo"
              src="assets/img/logo-sm.png"
              className="w-[75px] lg:w-[86px] h-[28px] lg:h-[32px]"
            />
          </Link>
          <Link to="/">
            <img
              alt="header-logo-left"
              src="assets/img/logo-left-sm.png"
              className="w-[140px] lg:w-[193px] h-[13px] lg:h-[18px]"
            />
          </Link>
        </div>
        <img
          alt="header-three-dot"
          src="assets/img/three-dot.png"
          className="block md:hidden w-[29px] pr-2 cursor-pointer"
          onClick={toggleMenu}
        />
        <div className="hidden md:flex items-center gap-4 lg:gap-8 xl:gap-11 text-[12px] xl:text-[18px] lg:pr-11">
          <Link to={"/"} className="hover:text-primary-hover cursor-pointer">
            Home
          </Link>
          <p className="text-[gray] cursor-not-allowed">Marketplace</p>
          {menuItems.map((item) => (
            <MenuItem
              key={item.label}
              width={item.width}
              subMenu={item.subMenu}
              to={item.to}
            >
              {item.label}
            </MenuItem>
          ))}
          <div
            className="flex items-center w-[80px] cursor-pointer"
            onClick={() => dispatch(toggleLoginModal(true))}
          >
            <img
              alt="header-sign-in"
              src="assets/img/menu-icons/sign-in.png"
              className="w-[18px] h-[16px] pr-1"
            />
            <p className="text-[#ED9B17]">Sign In</p>
          </div>
        </div>
      </div>
      {isMenuVisible && isMobile && (
        <div className="fixed inset-0 bg-[black] flex flex-col items-center pt-10 z-[1499] px-1 mt-1">
          <Link
            to={"/"}
            className="flex items-center w-full h-[36px] justify-center bg-[#090909] border border-[#666666] mb-2"
          >
            Home
          </Link>
          {/* <p className="flex items-center w-full h-[36px] justify-center bg-[#090909] border border-[#666666] my-2">
            Marketplace
          </p> */}
          {menuItems.map((item) => (
            <MenuItem
              key={item.label}
              width={item.width == 186 ? 301 : item.width}
              onClick={toggleMenu}
              subMenu={item.subMenu}
              mobile={true}
              to={item.to}
            >
              {item.label}
            </MenuItem>
          ))}
          <p onClick={() => dispatch(toggleLoginModal(true))} className="flex items-center w-full h-[36px] justify-center bg-[#090909] border border-[#666666]">
            Sign in
          </p>
        </div>
      )}
    </>
  );
};

export default Header;
