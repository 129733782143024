const SocialLogin = () => (
  <div className="flex w-full justify-center items-center gap-9 md:gap-[6vh]">
    <img
      alt="modal-logo"
      className="w-[70px] md:w-[8vh] md:h-[8vh] cursor-pointer"
      src="assets/img/login/x.png"
    />
    <img
      alt="modal-google"
      className="md:w-[8vh] md:h-[8vh] cursor-pointer"
      src="assets/img/login/google.png"
    />
    <img
      alt="modal-facebook"
      className="md:w-[8vh] md:h-[8vh] cursor-pointer"
      src="assets/img/login/facebook.png"
    />
  </div>
);

export default SocialLogin;
