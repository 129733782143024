import { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const intro = [
  "assets/img/prologue/1.png",
  "assets/img/prologue/2.png",
  "assets/img/prologue/3.png",
];

const ProloguePage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [indexIntro, setIndexIntro] = useState(1);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [selectedImage]);

  return (
    <div className={`w-full h-full flex flex-col bg-[#000000]`}>
      <Header />
      <div className="relative flex flex-col w-full lg:h-[120vh] items-center">
        <div
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/img/about-us/bg.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "#000000",
          }}
          className="z-[500] flex flex-col justify-center items-center w-full lg:h-[120vh]"
        >
          <img
            alt="prologue-thunder-logo"
            src="assets/img/thunder-logo.png"
            className="w-[328px] lg:w-[631px]"
          />
          <img
            alt="prologue-title"
            src="assets/img/prologue/title.png"
            className="w-[80%] lg:w-[400px] lg:h-[34px] lg:mt-2 z-[50]"
          />
          <img
            alt="prologue-title-line"
            src="assets/img/landing/title-line.png"
            className="w-[90%] lg:w-[641px] lg:h-[12px] mt-1 lg:mt-4 z-[50]"
          />
          <div className="flex items-center w-full justify-center h-fit">
            <div className="relative lg:w-[80%] overflow-hidden py-4">
              <>
                <button
                  onClick={() =>
                    setIndexIntro(
                      (indexIntro - 1 + intro.length) % intro.length
                    )
                  }
                  className="absolute left-[10%] md:left-0 lg:left-[20%] xl:left-[23%] 2xl:left-[29%] w-[20px] h-[35px] md:w-[30px] md:h-[50px] z-[100] top-[44%]"
                >
                  <img
                    alt="prologue-arrow-left"
                    src="assets/img/art-music/arrow-left.svg"
                  />
                </button>
                <button
                  onClick={() => setIndexIntro((indexIntro + 1) % intro.length)}
                  className="absolute right-[10%] md:right-0 lg:right-[20%] xl:right-[23%] 2xl:right-[29%] w-[20px] h-[35px] md:w-[30px] md:h-[50px] z-[100] top-[44%]"
                >
                  <img
                    alt="prologue-arrow-right"
                    src="assets/img/art-music/arrow-right.svg"
                  />
                </button>
              </>
              <div
                className="flex h-[250px] w-[400px] md:h-[300px] md:w-[486px] lg:h-[362px] lg:w-[644px] items-center justify-center transition-transform duration-700 ml-[50%]"
                style={{ transform: `translateX(${-indexIntro * 50}%)` }}
              >
                {intro.map((url, idx) => (
                  <div
                    key={idx}
                    className={`flex-shrink-0 ${
                      idx === indexIntro ? "w-[80%]" : "w-[50%] relative"
                    } transition-all duration-300`}
                  >
                    <div
                      className={`${
                        idx === indexIntro ? "hidden" : "block"
                      } absolute w-full h-full bg-[#00000094]`}
                    />
                    <img
                      alt="prologue-intro"
                      className="cursor-pointer h-fit"
                      src={url}
                      onClick={() => setSelectedImage(idx)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <p className="hidden lg:flex text-[19px] text-center mt-2 w-[784px] mb-24">
            The Prologue of our game is in progress.
            <br />
            <br />
            In this first stage you will discover a small fraction of planet
            Nebulous Prime, encountering unique characters and creatures that
            may help or hinder you. Begin to unravel the mysteries and uncover
            the lore of the world…and yourself.
            <br />
            <br />
            Subscribe to get the latest updates!
          </p>
          <div
            style={{
              backgroundImage: "url(assets/img/landing/section-line.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="lg:hidden mt-6 z-[100] w-full h-[26px] flex flex-col items-center"
          />
          <div className="w-full lg:hidden h-[502px] relative -mt-[26px] flex justify-center">
            <img
              alt="prologue-bg"
              src="assets/img/landing/landing-bg-2.png"
              className="w-full lg:hidden bg-[#0000009f] h-[502px] opacity-60"
            />
            <div className="lg:hidden absolute bg-[#0d141d] top-0 w-[90%] h-[500px] flex flex-col items-center">
              <p className="text-[14px] text-center mt-3 w-[90%] z-[200]">
                The Prologue of our game is in progress.
                <br />
                <br />
                In this first stage you will discover a small fraction of planet
                Nebulous Prime, encountering unique characters and creatures
                that may help or hinder you. Begin to unravel the mysteries and
                uncover the lore of the world…and yourself.
                <br />
                <br />
                Subscribe to get the latest updates!
              </p>
              <a
                href="https://linkedin.com/company/iplaymore"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-[200px] z-[1000] absolute cursor-pointer"
              >
                <img
                  alt="prologue-linkedin"
                  src="assets/img/prologue/linkedin.png"
                  className="lg:hidden w-[36px] h-[36px] mt-2"
                />
              </a>
              <img
                alt="prologue-character1-mb"
                src="assets/img/prologue/character1-mb.png"
                className="absolute left-0 -bottom-8 lg:hidden w-[247px] h-[249px]"
              />
              <img
                alt="prologue-character2-mb"
                src="assets/img/prologue/character2-mb.png"
                className="absolute right-0 bottom-0 lg:hidden w-[273px] h-[260px]"
              />
            </div>
          </div>
        </div>
        {selectedImage !== null && (
          <div
            className="z-[1200] w-screen top-0 left-0 h-screen fixed flex justify-center items-center bg-[#00000083]"
            onClick={() => setSelectedImage(null)}
          >
            <div
              className="flex items-center relative justify-center w-[80%] h-fit"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={() =>
                  setSelectedImage(
                    (selectedImage - 1 + intro.length) % intro.length
                  )
                }
                className="absolute -left-[5%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"
              >
                <img
                  alt="prologue-arrow-left"
                  src="assets/img/art-music/arrow-left.svg"
                />
              </button>
              <button
                onClick={() =>
                  selectedImage === intro.length - 1
                    ? setSelectedImage(0)
                    : setSelectedImage(selectedImage + 1)
                }
                className="absolute -right-[5%] hidden md:block w-[20px] h-[35px] md:w-[40px] md:h-[70px] z-[100] top-[40%]"
              >
                <img
                  alt="prologue-arrow-right"
                  src="assets/img/art-music/arrow-right.svg"
                />
              </button>
              <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
                wheel={{ step: 50 }}
              >
                <TransformComponent>
                  {selectedImage !== null && (
                    <img
                      alt="prologue-intro-selected"
                      src={intro[selectedImage]}
                      title={intro[selectedImage]}
                    />
                  )}
                </TransformComponent>
              </TransformWrapper>
            </div>
          </div>
        )}
        <div className="w-full -mt-20 flex">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ProloguePage;
