import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface authStates {
  activeStep: number;
}

const initialState: authStates = {
  activeStep: 0,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    }
  },
  extraReducers: (builder) => { },
})

export const {
  updateActiveStep,
} = authSlice.actions;
export default authSlice.reducer;
