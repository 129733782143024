import InitPage from './pages/initialPage';
import LandingPage from './pages/landingPage';
import ProloguePage from './pages/prologuePage';
import { Route, Routes } from 'react-router-dom';
import UpdateLogPage from './pages/updateLogPage';
import AboutUS from './pages/aboutUs';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RedirectingPage from './pages/redirectingPage';
import TwichToolPage from './pages/twichToolPage';
import Novel from './pages/novel';
import Testimonials from './pages/testimonials';
import VideoPage from './pages/videoPage';
import PrivacyPage from './pages/privacy';
import ArtPage from './pages/artPage';
import LoginModal from './components/modal/login';
import RecapachaModal from './components/modal/recapachaModal';
import RegisterPage from './pages/register';
import TermsPage from './pages/terms';


function App() {
  if (window.location.hostname === 'iplaymore.vercel.app') {
    window.location.href = 'https://iplaymore.com/';
  }

  return (
    <div className="flex">
      <ToastContainer />
      <RecapachaModal />
      <LoginModal />
      {window.location.pathname !== '/subscribe' && <InitPage />}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/prologue" element={<ProloguePage />} />
        <Route path="/twitch-tool" element={<TwichToolPage />} />
        <Route path="/novel" element={<Novel />} />
        <Route path="/update-logs" element={<UpdateLogPage />} />
        <Route path="/videos" element={<VideoPage />} />
        <Route path="/art-music" element={<ArtPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/iplaymore" element={<AboutUS />} />
        <Route path="/subscribe" element={<RedirectingPage />} />
        <Route path="/signup" element={<RegisterPage />} />
      </Routes>
    </div>
  );
}

export default App;