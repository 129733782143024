import Header from "../../components/header";
import Footer from "../../components/footer";
import LogItem from "../../components/update/log-item";

const AboutUS = () => {
  return (
    <div className={`w-full h-full flex flex-col bg-[#000000]`}>
      <Header />
      <div
        style={{
          backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/img/about-us/bg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "#000000",
        }}
        className="relative flex flex-col w-full h-fit items-center"
      >
        <div
          style={{
            backgroundImage: "url(assets/img/about-us/logo-line.png)",
            backgroundSize: "cover",
            backgroundPosition: "top",
          }}
          className="relative z-[100] w-full lg:h-[14px] flex mt-[140px]"
        >
          <img
            alt="about-us-logo"
            src="assets/img/about-us/logo.png"
            className="w-[201px] lg:w-[486px] h-[83px] lg:h-[200px] mx-auto -mt-[110px] lg:-mt-[90px]"
          />
        </div>
        <div className="flex flex-col w-full mt-4 lg:mt-12 mb-28 lg:mb-20 z-[200] lg:pt-20 2xl:pt-0">
          <div className="flex w-full flex-col lg:flex-row justify-between h-fit text-[14px] lg:text-[20px] px-4 md:px-8 lg:px-[68px]">
            <div className="flex flex-col lg:w-[487px]">
              <img
                alt="about-us-who-we-are"
                src="assets/img/about-us/who-we-are.png"
                className="w-[246px] lg:h-[18px] mb-4"
              />
              <p>
                We are a revolutionary transmedia company contracting worldwide
                game developers. Each with a passion for making games,
                contributing to designing, creating and developing our video
                game.
              </p>
              <img
                alt="about-us-about-thunder"
                src="assets/img/about-us/about-thunder.png"
                className="w-[350px] lg:w-[412px] -ml-7 lg:h-[78px] mt-4 -mb-2"
              />
              <p>
                Inspired by the best elements of Quake, Tribes, Planetside,
                Skyrim, Silk Road Online, Everquest, World of Warcraft, and the
                Unreal Tournament video game franchises, THUNDER© is our hybrid,
                <br />
                <br />
                open-world game where teams work together to control regional
                territories all while unleashing powerful devices and exotic
                scepters.
                <br />
                <br />
                THUNDER© will include multiple unique playable characters. Each
                will be able to complete narrative-based solo/co-op quests that
                benefit your play experience.
                <br />
                <br />
                The fast-paced, easy to access action will have you coming back
                for more and wanting to unravel the many secrets hidden
                throughout the world by rewarding your success.
                <br />
                <br />
                Our sister project will be a five-season, binge-worthy,
                character-driven television show. Entitled THUNDER© Rocks,
                players and viewers of all ages will fall in love with this
                expansive world of intertwining stories and music created
                directly in the game world and Unreal Engine.
              </p>
            </div>
            <div className="flex flex-col lg:w-[597px] text-left lg:text-right item-start lg:items-end mt-8 lg:mt-0">
              <img
                alt="about-us-history"
                src="assets/img/about-us/history.png"
                className="w-[360px] lg:w-[490px] lg:h-[24px] mb-4"
              />
              <p className="lg:w-[430px]">
                In 2019, we developed our Unreal Engine, Bear Isle prototype. In
                2020 we took another step forward with the creation of our
                Intrepid vertical slice, which showcases our outdoor arena
                gameplay.{" "}
              </p>
              <img
                alt="about-us-mission"
                src="assets/img/about-us/mission.png"
                className="w-[264px] lg:w-[294px] -ml-6 lg:ml-0 lg:-mr-5 lg:h-[78px] mt-4 -mb-4"
              />
              <p>
                Our ‘Play, For Profit’ product model is our custom design genre.
                It is revolutionary by marketing directly to gamers using our
                proprietary Influencer Marketing tool that grants winners prize
                codes across various social networks to redeem online at
                iPlayMore.com
              </p>
              <img
                alt="about-us-profit"
                src="assets/img/about-us/profit.png"
                className="w-[360px] lg:w-[530px] lg:h-[18px] mt-8"
              />
              <p className="mt-8 lg:w-[460px]">
                The ‘Play, For Profit’ product model is our custom design genre.
                It is revolutionary by marketing directly to gamers using our
                proprietary “Influencer Marketing Tool” that grants winners Game
                Gear prize codes across various social networks to redeem in our
                online Marketplace at iPlayMore.com
                <br />
                <br />
                Once redeemed, items increase in rarity and value over time, and
                can be sold for a profit at any time. When offline, free players
                will use and increase the value of items to the surprise of the
                owners who will log back in and discover their Game Gear has
                increased in value.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center px-4 md:px-8 lg:px-0 -mb-[80px] lg:mb-0">
            <img
              alt="about-us-contact-us"
              src="assets/img/about-us/contact-us.png"
              className="w-[360px] lg:w-[724px] lg:h-[74px] mt-8"
            />
            <p className="text-[16px] lg:text-[24px] text-center">
              Send an email to get in touch with the development team at{" "}
              <a href="mailto:thunder@iplaymore.com">
                <span className="text-primary-hover hover:text-secondary-hover">
                  {" "}
                  thunder@iplaymore.com
                </span>
              </a>
              <br />
              <br />
              As a private company, we are willing to share this lucrative
              opportunity with sophisticated investors.
              <br />
              <br />
              Do you have experience creating video games and want to join our
              team? Find us on{" "}
              <a
                href="https://linkedin.com/company/iplaymore"
                target="_blank"
                rel="noopener noreferrer"
                className="z-[300] cursor-pointer ml-2"
              >
                <img
                  alt="about-us-linkedin"
                  src="assets/img/prologue/linkedin.png"
                  className="inline-block w-[24px] h-[24px] lg:w-[63px] lg:h-[63px]"
                />
              </a>{" "}
              <p className="hidden lg:inline-block"></p>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUS;
