import { useState } from 'react';
interface Member {
    name: string;
    linkedin: string;
    role: string;
    summary: string
}

const TestimonialItem = ({ member }: { member: Member }) => {
    const [isSummaryVisible, setIsSummaryVisible] = useState(false);

    const toggleSummary = () => {
        setIsSummaryVisible(!isSummaryVisible);
    };

    const summaryToDisplay = isSummaryVisible
        ? member.summary
        : member.summary.split(' ').slice(0, 40).join(' ') + (member.summary.split(' ').length > 50 ? '...' : ''); // Display first 50 words with "..." if applicable

    return (
        <>
            <div className="bg-[#0C0C0C] z-[100] w-[95%] h-fit flex flex-col pb-4 md:pb-8 px-4 md:px-7 py-2 md:py-7 mb-11 shadow-super-strong">
                <p className="text-third-hover text-[16px] md:text-[20px]">
                    <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="text-third-hover">
                        {member.name} - {member.role}
                    </a>
                </p>
                <p className="text-[16px] md:text-[20px] mt-4">
                    {summaryToDisplay}
                    {member.summary.split(' ').length > 50 && !isSummaryVisible && (
                        <button onClick={toggleSummary} className="text-third-hover mt-2 ml-2">[read more]</button>
                    )}
                    {isSummaryVisible && (
                        <button onClick={toggleSummary} className="text-third-hover mt-2 ml-2">[read less]</button>
                    )}
                </p>
            </div>
        </>
    )
}
export default TestimonialItem;