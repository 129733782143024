import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../state/hooks";
import { toggleLoginModal, toggleRecapachaModal } from "../../state/modal";
import { Link } from "react-router-dom";
import Divider from "../shared/Divider";
import Input from "../shared/Input";
import Button from "../shared/Button";
import SocialLogin from "../login/SocialLogin";

const LoginModal = () => {
  const dispatch = useAppDispatch();
  const loginModal = useAppSelector((state) => state.modalState.loginModal);
  const [rememberBrowser, setRememberBrowser] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    document.body.style.overflow = loginModal ? "hidden" : "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [loginModal]);

  return (
    <div
      className={`z-[5000] overflow-auto md:overflow-hidden fixed flex items-center justify-center inset-0 bg-[black] transition-opacity duration-300 ease-in-out ${
        loginModal ? "md:bg-opacity-80" : "bg-opacity-0 pointer-events-none"
      }`}
    >
      <div
        onClick={() => dispatch(toggleLoginModal(false))}
        className={`z-[200] absolute top-4 right-6 text-white cursor-pointer text-[18px] md:text-[2vh] ${
          loginModal ? "md:block hidden" : "hidden"
        }`}
      >
        X Close
      </div>
      <div
        className={`w-full md:w-[75vh] flex justify-center h-full relative transition-all duration-300 transform ${
          loginModal
            ? "translate-y-0 opacity-100"
            : "-translate-y-full opacity-0"
        }`}
      >
        <img
          alt="modal-header"
          src="assets/img/login/modal-header.png"
          className="w-full h-[2.4vh] absolute -top-[2px] z-[200]"
        />
        <img
          alt="modal-header"
          src="assets/img/login/modal-header.png"
          className="w-full md:block hidden h-[2.4vh] absolute rotate-180 -bottom-[2px] z-[200]"
        />
        <div className="flex relative flex-col md:justify-between bg-[rgb(0,0,0)] w-full md:w-[65vh] h-[1100px] md:h-full items-center mt-10 md:mt-0 md:p-[4vh]">
          <img
            alt="modal-header"
            src="assets/img/login/modal-header.png"
            className="md:hidden w-full h-[2.4vh] absolute rotate-180 -bottom-[2px]"
          />
          <img
            alt="modal-logo"
            src="assets/img/login/logo.png"
            className="w-[290px] md:w-[30vh]"
          />

          <Divider text="LOGIN WITH" />
          <SocialLogin />
          <Divider text="OR" />

          <div className="w-full gap-[34px] text-[18px] md:text-[2vh] flex flex-col px-3 md:px-0">
            <Input
              type="email"
              onChange={(value) => setEmailAddress(value)}
              placeholder="Email"
            />
            <Input
              type="password"
              onChange={(value) => setPassword(value)}
              placeholder="Password"
            />
          </div>

          <div className="w-full flex justify-between items-start md:items-center gap-4 mt-[18px] md:text-[1.6vh] px-3 md:px-0">
            <div
              onClick={() => setRememberBrowser(!rememberBrowser)}
              className="text-[white] flex items-center gap-1 2xl:gap-2 cursor-pointer"
            >
              <div
                className={`w-[2vh] h-[2vh] border border-[#525252] rounded-full ${
                  rememberBrowser ? "bg-[#1ab5f8]" : "bg-[#d9d9d9]"
                }`}
              ></div>
              Remember Browser
            </div>
            <div className="flex flex-col md:flex-row gap-[16px] md:gap-[31px]">
              <a
                href="#"
                className="text-[white] hover:text-fourth-hover cursor-pointer"
              >
                Forgot Email?
              </a>
              <a
                href="#"
                className="text-[white] hover:text-fourth-hover cursor-pointer"
              >
                Forgot Password?
              </a>
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center gap-[28px] md:gap-[4vh] mt-[24px] md:mt-[4vh] text-[18px] md:text-[2vh]">
            <Button
              onClick={() => dispatch(toggleRecapachaModal(true))}
              className="w-[98px]"
            >
              LOGIN
            </Button>

            <Divider text="OR" />

            <Link
              onClick={() => dispatch(toggleLoginModal(false))}
              to="/signup"
              className="w-[136px] border-[0.23vh] border-fourth-hover h-[51px] md:h-[6vh] bg-[#131313] rounded-[20px] text-[white] flex justify-center items-center"
            >
              REGISTER
            </Link>
          </div>
          <p className="text-[12px] mt-10 md:mt-[4vh] text-[white] text-center">
            This site is protected by iPlayMore's Privacy Policy and Terms of
            Use apply.{" "}
            <a
              target="_blank"
              href="https://iplaymore.com/privacy"
              className=" underline hover:text-fourth-hover"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              href="https://iplaymore.com/terms"
              className=" underline hover:text-fourth-hover"
            >
              Terms of Service
            </a>{" "}
            apply.
          </p>
          <div
            onClick={() => dispatch(toggleLoginModal(false))}
            className={`z-[200] absolute bottom-16 text-white cursor-pointer text-[18px] md:text-[2vh] ${
              loginModal ? "block md:hidden" : "hidden"
            }`}
          >
            X Close
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
