interface DividerProps {
  text: string;
}

const Divider = ({ text }: DividerProps) => (
  <div className="flex w-full justify-center items-center gap-4 my-4 md:px-2">
    <div className="h-[1px] w-full bg-[#d39032]"></div>
    <div className="text-[white] flex-shrink-0 text-[18px] md:text-[2vh]">
      {text}
    </div>
    <div className="h-[1px] w-full bg-[#d39032]"></div>
  </div>
);

export default Divider;
