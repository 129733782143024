import { useInView } from "react-intersection-observer";

const Craft = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  return (
    <>
      <div
        className="hidden lg:flex absolute w-[400px] xl:w-[560px] 2xl:w-[709px] left-5 top-16 xl:top-6 2xl:-top-8 z-[200]"
      >
        <img
          alt="craft-detect"
          src="assets/img/landing/craft-detect.png"
          className={`absolute w-[180px] -scale-y-100 bottom-0 right-0 xl:bottom-4 xl:right-4 h-[62px] z-[50] transition-transform duration-[1000ms] ${
            inView ? "translate-y-0" : "translate-y-[100%]"
          }`}
        />
        <img
          alt="craft-detect-left"
          src="assets/img/landing/craft-detect.png"
          className={`absolute w-[180px] -scale-x-100 xl:top-14 xl:left-6 h-[62px] z-[50] transition-transform duration-[1000ms] ${
            inView ? "translate-y-0" : "translate-y-[-100%]"
          }`}
        />
        <img
          alt="craft-item"
          src="assets/img/landing/craft-item.png"
          className={`w-full h-full z-[50] transition-all duration-[1000ms] ${
            inView ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        />
      </div>
      <div
        ref={ref}
        className={`z-[200] flex w-full justify-end px-2 lg:px-[40px] pt-6 sm:pt-12 overflow-x-hidden  lg:pt-0`}
      >
        <div
          className={`w-full px-2 lg:w-[57%] 2xl:w-[53%] items-start overflow-x-hidden flex flex-col transition-transform duration-[1000ms] ${
            inView ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <p className="w-[90%] hidden sm:block text-[#FFB834] md:w-[590px] text-[24px] xl:text-[32px] mt-2 md:mt-0">
            Upgrade
          </p>
          <p className="text-[14px] xl:text-[16px] w-full mt-1">
            Enhance your Game Gear and devices to release their greater
            ascending potential to become more powerful! During your journey,
            you’ll be able to discover recipes and develop incredible devices
            with different features and playstyles, making your experience
            exclusive, personal and unforgettable!
          </p>
          <p className="w-[90%] hidden sm:block text-[#FFB834] md:w-[590px] text-[24px] xl:text-[32px] mt-2 md:mt-0">
            Explore
          </p>
          <p className="text-[14px] xl:text-[16px] mt-6 text-right sm:text-left w-full sm:mt-1">
            Players can fly freely in the sky with their own unique sets of
            wings for each character or dive into the depths of the oceans,
            cross vast wilderness, and even visit unexplored islands.  We’ve
            spent years developing our explosive gameplay model to provide a
            fun, fair, and fulfilling experience for all players, regardless of
            skill level and playstyle. Alone or with your friends, the world of
            Nebulous Prime will amaze you!
          </p>
        </div>
      </div>
    </>
  );
};
export default Craft;
