import { useInView } from "react-intersection-observer";
import IntroContent from "../../components/landing/introContent";

const SecondSection = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  return (
    <div className="flex flex-col w-full mt-14 sm:mt-10">
      <div className="relative flex flex-col items-center justify-between w-full h-auto">
        <div ref={ref} className="absolute top-[100px] h-[500px] w-full"></div>
        <div className="hidden sm:block z-[200] w-full h-[26px] flex-col items-center">
          <div
            style={{
              backgroundImage: "url(assets/img/landing/section-line.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="z-[100] w-full h-[26px] -mt-5 flex flex-col items-center rotate-180"
          >
            &nbsp;
          </div>
          <div className="flex flex-col items-center">
            <img
              alt="landing-make-your-choice"
              src="assets/img/landing/make-your-choice.png"
              className="w-[165px] md:w-[252px] h-[12px] md:h-[22px] mt-6 z-[50]"
            />
          </div>
        </div>
        <div className="sm:hidden bg-[black] h-16 z-[200] w-full flex flex-col items-center justify-between absolute -top-16">
          <img
            alt="landing-section-line-mb"
            src="assets/img/landing/section-line-mb.png"
            className="w-full md:w-[252px] h-[12px] z-[50] -mt-[1px]"
          />
          <p className="text-[20px] text-third-hover drop-shadow-[0_9px_5.5px_#000]">
            Make your choice
          </p>
          <img
            alt="landing-section-line-mb"
            src="assets/img/landing/section-line-mb.png"
            className="w-full md:w-[252px] h-[12px] z-[50] rotate-180 -mb-[1px]"
          />
        </div>
        <div
          style={{
            backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(assets/img/landing/second-bg-mb.png)",
            backgroundSize: "cover",
            backgroundPosition: "center 30%",
          }}
          className="z-[100] w-full h-full flex flex-col items-center -mt-6"
        >
          <IntroContent />
        </div>
      </div>
      <div
        style={{
          backgroundImage: "url(assets/img/landing/section-line.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="z-[100] hidden w-full h-[26px] sm:flex flex-col items-center"
      ></div>
    </div>
  );
};
export default SecondSection;
