import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.css";
import Input from "../../components/shared/Input";
import { useState } from "react";
import Button from "../../components/shared/Button";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { updateActiveStep } from "../../state/auth";

const RegisterPage = () => {
  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const activeStep = useAppSelector((state) => state.authSlice.activeStep);
  const dispatch = useAppDispatch();
  return (
    <div
      style={{
        backgroundImage: "url(assets/img/auth/bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className={`relative w-full h-full flex flex-col items-center text-[white]`}
    >
      <Header />
      <div className="w-full md:w-[676px] h-full flex flex-col items-center mt-5 z-10 mb-[166px]">
        <img
          alt="modal-logo"
          src="assets/img/login/logo.png"
          className="w-[252px] md:w-[276px]"
        />
        <p className="text-[16px] md:text-[20px]">
          Create your own iPlayMore account
        </p>
        <p className="text-[13px] md:text-[16px]">
          Your iPlayMore account will be linked to our games and experiences
        </p>
        <div className="flex flex-col gap-6 w-full mt-8">
          <div className="flex flex-col w-full gap-4 px-20 text-[18px] md:text-[20px] relative group">
            <p>Nickname</p>
            <Input
              className="!h-[51px]"
              type="text"
              onChange={(value) => setNickName(value)}
              placeholder="Nickname"
            />
            <div className="invisible hidden group-hover:visible absolute -right-[50%] 2xl:-right-[64%] -top-3 leading-[20px] text-[14px] 2xl:text-[18px] xl:flex h-fit z-[20] border border-[white] text-white text-sm rounded-sm shadow-lg transition-opacity duration-300">
              <div className="flex w-full h-full bg-[black] z-[20] p-2 pr-4">
                Your nickname must meet the following requirements:
                <br />
                -Do not contain swear, racist words or profanity
                <br />
                -Do not contain special characters ( _ ! : £ % ...)
                <br />
                -Contain at least 1 lowercase letter
                <br />
                -Be at least 3 characters long
                <br />
                -Can’t exceed 15 characters
                <br />
                -Can’t use spaces between words
              </div>
              <div className="absolute top-[calc(50%-20px)] skew-x-[45deg] -rotate-[22deg] -left-3 w-12 h-10 bg-[black] border border-[white] z-[10]"></div>
            </div>
          </div>
          <div className="flex flex-col gap-4 px-20 text-[18px] md:text-[20px]">
            <p>Email</p>
            <Input
              className="!h-[51px]"
              type="email"
              onChange={(value) => setEmail(value)}
              placeholder="Email"
            />
          </div>
          <div className="flex flex-col gap-4 px-20 text-[18px] md:text-[20px] relative group">
            <p>Password</p>
            <Input
              className="!h-[51px]"
              type="password"
              onChange={(value) => setPassword(value)}
              placeholder="Password"
            />
            <div className="invisible hidden group-hover:visible absolute -right-[50%] 2xl:-right-[64%] top-2 text-[14px] 2xl:text-[18px] xl:flex h-fit z-[20] border border-[white] text-white text-sm rounded-sm shadow-lg transition-opacity duration-300">
              <div className="flex w-full h-full bg-[black] z-[20] p-2 pr-4">
                Your password must meet the following requirements:
                <br />
                -Contain at least 1 uppercase letter
                <br />
                -Contain at least 1 lowercase letter
                <br />
                -Contain at least 1 special character
                <br />
                -Contain at least 1 number
                <br />
                -Be at least 8 characters long
              </div>
              <div className="absolute top-[calc(50%-20px)] skew-x-[45deg] -rotate-[22deg] -left-3 w-12 h-10 bg-[black] border border-[white] z-[10]"></div>
            </div>
          </div>
          <div className="flex flex-col gap-4 px-20 text-[18px] md:text-[20px]">
            <p>Confirm Password</p>
            <Input
              className="!h-[51px]"
              type="password"
              onChange={(value) => setConfirmPassword(value)}
              placeholder="Password"
            />
          </div>
          <div className="flex justify-between mt-20 px-[28px] text-[18px] md:text-[20px]">
            <Button
              onClick={() => {
                dispatch(updateActiveStep(activeStep - 1));
              }}
              className="w-[98px] !h-[51px]"
            >
              Back
            </Button>
            <div className="flex items-center gap-2">
              {[0, 1, 2].map((index) => (
                <button
                  key={index}
                  onClick={() => {
                    dispatch(updateActiveStep(index));
                  }}
                  className={`w-5 h-5 border border-[#525252] rounded-full ${
                    activeStep === index ? "bg-[#1ab5f8]" : "bg-[#d9d9d9]"
                  }`}
                />
              ))}
            </div>
            <Button
              onClick={() => {
                dispatch(updateActiveStep(activeStep + 1));
              }}
              className="w-[98px] !h-[51px]"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <div className="md:w-[676px] w-full h-full absolute bg-[black] bg-opacity-[93%]"></div>
      <div className="w-full flex absolute bottom-0">
        <Footer type="sm" />
      </div>
    </div>
  );
};

export default RegisterPage;

<div className="w-0 h-0 border-y-[20px] border-y-transparent border-r-[40px] border-r-[black]"></div>;
