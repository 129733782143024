import Header from "../../components/header";
import Footer from "../../components/footer";
import LogItem from "../../components/update/log-item";

const UpdateLogPage = () => {
  return (
    <div className={`w-full h-fit flex flex-col bg-[#000000]`}>
      <img
        alt="update-log-bg-1"
        src="assets/img/update/bg-1.png"
        className="w-full opacity-80 absolute h-[100vh]"
      />
      <img
        alt="update-log-bg-2"
        src="assets/img/update/bg-2.png"
        className="w-full absolute h-[100vh]"
      />
      <Header />
      <div className="relative flex flex-col w-full h-full items-center">
        <div className="bg-[#0d131c] bottom-0 z-[100] w-[90%] md:w-[822px] h-[102vh] -mt-16 pt-[100px] lg:pt-[12vh] flex flex-col items-center">
          <div className="flex flex-col items-center w-full md:w-[750px] h-[84vh] md:h-[90vh]">
            <img
              alt="update-log-title"
              src="assets/img/update/title.png"
              className="w-[95%] md:w-[716px] md:h-[48px] mb-4 md:mb-7"
            />
            <div className="flex flex-col items-center w-full md:w-[750px] h-[90vh] overflow-y-auto pt-4">
              {Array.from({ length: 1 }, (_, index) => (
                <LogItem key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="bottom-0 z-[100] w-full pointer-events-none -mt-14 flex flex-col items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default UpdateLogPage;
