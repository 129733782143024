import { useState } from "react";
import { Link } from "react-router-dom";

type SubMenu = {
  label: string;
  imgName: string;
  to: string;
  size: number;
};

type MenuItemProps = {
  to?: string;
  mobile?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  subMenu?: SubMenu[];
  index?: number;
  width?: number;
};

export const MenuItem = ({
  children,
  to,
  mobile = false,
  onClick,
  subMenu,
  width,
}: MenuItemProps) => {
  const [isSubMenuVisible, setSubMenuVisible] = useState(false); // Add state for sub-menu visibility

  return (
    <>
      {mobile ? (
        subMenu && subMenu.length > 0 ? (
          <div className="mb-2 flex flex-col justify-center items-center w-full h-fit relative">
            <div
              className="flex items-center w-full h-[36px] justify-center bg-[#090909] border border-[#666666]"
              onClick={() => setSubMenuVisible(!isSubMenuVisible)}
            >
              {children}
              <img
                alt="menu-item-sub-menu"
                src={`assets/img/menu-icons/sub-menu.png`}
                className={`w-[9px] ml-1 transition-transform duration-200 ${
                  isSubMenuVisible ? "-rotate-90" : ""
                }`}
              />{" "}
              {/* Rotate icon based on visibility */}
            </div>
            {isSubMenuVisible && (
              <div
                className={`${
                  width && width > 300 ? `grid-cols-3` : "grid-cols-2"
                }  grid left-0 gap-x-8 top-6 py-2 h-fit bg-cover bg-center`}
              >
                {subMenu.map((item, subIndex) => (
                  <div key={item.label}>
                    {item.to.startsWith("http") ? (
                      <a
                        key={subIndex}
                        className="hover:text-primary-hover px-2 pb-[2px] flex text-[12px] justify-start items-center w-full h-[34px]"
                        href={item.to}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="w-8 h-8 flex items-center justify-center">
                          <img
                            alt="menu-item-sub-menu-icon"
                            src={`assets/img/menu-icons/${item.imgName}`}
                            className={`w-[28px] pr-2`}
                          />
                        </div>
                        {item.label}
                      </a>
                    ) : (
                      <Link
                        key={subIndex}
                        className="hover:text-primary-hover px-2 pb-[2px] text-[12px] flex justify-start items-center w-full h-[34px]"
                        to={item.to}
                      >
                        <div className="w-8 flex items-center justify-center">
                          <img
                            alt="menu-item-sub-menu-icon"
                            src={`assets/img/menu-icons/${item.imgName}`}
                            className={`w-[32px] pr-2`}
                          />
                        </div>
                        {item.label}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <Link
            onClick={onClick}
            className="mb-1 flex justify-center items-center w-full h-[36px] bg-[#090909] border border-[#666666]"
            to={to as string}
          >
            {children}
          </Link>
        )
      ) : subMenu && subMenu.length > 0 ? (
        <div className="relative group flex items-center gap-[6px]">
          <p className="cursor-pointer hover:text-primary-hover hover:underline underline-offset-4">
            {children}
          </p>
          <img
            alt="menu-item-sub-menu-icon"
            src={`assets/img/menu-icons/sub-menu.png`}
            className={`xl:w-[12px] w-[9px] transition-transform duration-200 group-hover:-rotate-90`}
          />
          <div
            className={`absolute left-0 top-4 xl:top-4 py-2 h-fit hidden group-hover:grid`}
            style={{ width: width }}
          >
            <div className={`bg-[#090909] rounded-bl-[18px] rounded-br-[18px] py-2 grid mt-3 ${width && width > 300 ? "grid-cols-2" : ""}`}>
              {subMenu.map((item, subIndex) => (
                <div key={item.label}>
                  {item.to.startsWith("http") ? (
                    <a
                      key={subIndex}
                      className="hover:text-primary-hover px-2 pb-[2px] flex justify-start items-center w-full h-[34px]"
                      href={item.to}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="w-8 flex items-center justify-center">
                        <img
                          alt="menu-item-sub-menu-icon"
                          src={`assets/img/menu-icons/${item.imgName}`}
                          className={`w-[${item.size}px] pr-2`}
                        />
                      </div>
                      {item.label}
                    </a>
                  ) : (
                    <Link
                      key={subIndex}
                      className="hover:text-primary-hover px-2 pb-[2px] flex justify-start items-center w-full h-[34px]"
                      to={item.to}
                    >
                      <div className="w-8 flex items-center justify-center">
                        <img
                          alt="menu-item-sub-menu-icon"
                          src={`assets/img/menu-icons/${item.imgName}`}
                          className={`w-[${item.size}px] pr-2`}
                        />
                      </div>
                      {item.label}
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Link to={to ?? ""} className="hover:text-primary-hover cursor-pointer">
          {children}
        </Link>
      )}
    </>
  );
};
