import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface modalStates {
  loginModal: boolean;
  recapachaModal: boolean;
}

const initialState: modalStates = {
  loginModal: false,
  recapachaModal: false,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleLoginModal: (state, action: PayloadAction<boolean>) => {
      state.loginModal = action.payload;
    },
    toggleRecapachaModal: (state, action: PayloadAction<boolean>) => {
      state.recapachaModal = action.payload;
    },
  },
  extraReducers: (builder) => { },
})

export const {
  toggleLoginModal,
  toggleRecapachaModal,
} = modalSlice.actions;
export default modalSlice.reducer;
