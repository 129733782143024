import { Link } from "react-router-dom";
import { MenuItem } from "../header/menuItem";
import { useEffect, useRef, useState } from "react";
import { subscribe } from "../../api/subscribe";
import { toast } from "react-toastify";

const Footer = ({ type }: { type?: string }) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleSubscribe = async () => {
    const inputText = inputRef.current?.textContent || "";
    const res = await subscribe(inputText);
    if (res.success) {
      toast.success("Confirmation email sent");
    } else {
      toast.success(res.error);
    }
  };
  return (
    <div
      style={{
        backgroundImage: `url(assets/img/footer-bg${
          type === "sm" ? "-sm" : ""
        }.png)`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
      className={` pointer-events-auto z-[500] flex flex-col w-full ${
        type === "sm" ? "h-[134px] md:py-1" : "h-[200px] md:h-[368px] md:py-4"
      } bg-cover items-center md:px-4 lg:px-10 justify-between -mt-1`}
    >
      <div
        className={`${
          type === "sm" ? "hidden" : "block"
        } flex flex-col items-center pt-9 md:pt-12`}
      >
        <img
          alt="footer-sub-title"
          src="assets/img/landing/sub-title.png"
          className="mt-1 w-[269px] md:w-[592px] lg:h-[44px] z-[50]"
        />
        <p className="text-[10px] w-[304px] md:w-full text-center md:text-[16px] lg:text-[20px] lg:h-fit">
          Receive updates and calls to action as releases of our game become
          exclusively available online.
        </p>
        <div className="flex h-[28px] md:h-[68px] gap-2 items-center">
          <div className="relative">
            <div
              ref={inputRef}
              style={{
                backgroundImage: "url(assets/img/landing/text-box.png)",
                backgroundSize: "cover",
              }}
              contentEditable={true}
              suppressContentEditableWarning={true}
              className="leading-[48px] md:leading-[78px] focus:outline-none text-center text-[10px] md:text-[20px] hover:text-secondary-hover font-[400] flex items-center justify-center w-[201px] mt-1 md:w-[486px] h-[28px] md:h-[62px] md:mt-4 z-[50]"
              onPaste={(e) => {
                e.preventDefault();
                const text = e.clipboardData.getData("text");
                document.execCommand("insertText", false, text);
              }}
            >
              {""}
            </div>
          </div>
          <div
            style={{
              backgroundImage: "url(assets/img/landing/sub-btn.png)",
              backgroundSize: "cover",
            }}
            onClick={handleSubscribe}
            className="cursor-pointer text-[7px] md:text-[14px] hover:text-secondary-hover font-[400] flex items-center justify-center md:mt-4 w-[57px] h-[24px] md:w-[138px] md:h-[60px] z-[50]"
          >
            Subscribe
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full lg:w-[800px] items-center justify-between h-[120px]">
        <div className="flex items-center gap-1 md:gap-3 mt-4">
          <Link to="/iplaymore">
            <img
              alt="footer-logo"
              src="assets/img/logo-sm.png"
              className="w-[50px] md:w-[122px] md:h-[44px]"
            />
          </Link>
          <Link to="/">
            <img
              alt="footer-logo-left"
              src="assets/img/logo-left-sm.png"
              className="w-[93px] md:w-[226px]"
            />
          </Link>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-x-1 md:gap-4 text-[8px] md:text-[11px] mt-[2px] md:mt-1">
          <MenuItem to="/">Home</MenuItem>
          <MenuItem to="/">Marketplace</MenuItem>
          <MenuItem to="/novel">Novel</MenuItem>
          <MenuItem to="/art-music">Art & Music</MenuItem>
          <MenuItem to="/prologue">Prologue</MenuItem>
          <MenuItem to="/twitch-tool">Twitch Tool</MenuItem>
          <MenuItem to="/iplaymore">Who we are</MenuItem>
          <MenuItem to="/testimonials">Testimonials</MenuItem>
          <MenuItem to="/update-logs">Update Logs</MenuItem>
        </div>
        <div className="text-[8px] md:text-[11px] mt-[2px] md:mt-1">
          © iPlayMore - ALL RIGHT RESERVED{" "}
          <MenuItem to="/privacy">
            <p className="text-[#FFA81D] hover:text-secondary-hover inline-block">
              Privacy Policy
            </p>
          </MenuItem>
        </div>
        <p className="text-[8px] md:text-[11px] mt-[2px] md:mt-1">
          Proprietary Copyright Intellectual Property of IMU Studios
        </p>
      </div>
    </div>
  );
};

export default Footer;
