import { useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import React from 'react';
import Book from '../../components/book/Book';

const Novel = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className={`w-full h-full flex flex-col bg-[#000000]`}>
      <Header />
      <div style={{ backgroundImage: 'url(assets/img/novel/novel-bg.png)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }} className='h-full flex justify-center pb-0 md:pb-40 items-center relative'>
        <img alt='novel-bg' src='assets/img/landing/landing-bg-2.png' className='w-full bg-[#00000035] h-full absolute' />
        <div className='z-50 w-fit h-[calc(100vh-118px)] md:h-fit md:pb-[120px] overflow-hidden'>
          <Book isMobile={isMobile} menu="novel"></Book>
        </div>
      </div>
      <div className='relative flex flex-col w-full lg:h-fit items-center'>
        <div className="bottom-0 z-[100] w-full h-[600px] -mt-[400px] lg:-mt-[440px] pointer-events-none justify-end flex flex-col items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Novel;