import { useEffect, useState } from "react";
import Header from "../../components/header";
import "./index.css";
import FirstSection from "./firstSection";
import SecondSection from "./secondSection";
import FourthSection from "./fourthSection";
import LastPage from "./lastPage";

const LandingPage = () => {
  return (
    <div className={`w-full h-full flex flex-col bg-[#00000071]`}>
      <Header />
      <div
        style={{
          backgroundImage: "url(assets/img/privacy.png)",
          backgroundRepeat: "repeat",
          backgroundSize: "auto",
          backgroundPosition: "center",
        }}
        className="flex flex-col h-fit w-full items-center"
      >
        <FirstSection />
        <SecondSection />
        <FourthSection />
        <LastPage />
      </div>
    </div>
  );
};

export default LandingPage;
