interface ButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
}

const Button = ({ onClick, children, className = "" }: ButtonProps) => (
  <button
    onClick={onClick}
    className={`border-[0.23vh] border-fourth-hover h-[51px] md:h-[6vh] bg-[#131313] rounded-[20px] text-[white] flex justify-center items-center ${className}`}
  >
    {children}
  </button>
);

export default Button;
