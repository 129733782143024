import { useInView } from "react-intersection-observer";

const IntroContent = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  return (
    <div
      ref={ref}
      className={`flex mt-[120px] lg:mt-[240px] flex-col z-[200] lg:flex-row w-full justify-between px-4 md:px-8 pb-8 md:pb-8 xl:px-[60px] xl:pb-[50px] overflow-x-hidden`}
    >
      <div
        className={`w-full lg:w-[613px] md:flex flex-col transition-transform duration-[1000ms] ${
          inView ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <img
          alt="intro-brood"
          src="assets/img/landing/brood.png"
          className="w-[360px] md:w-[496px] h-[32px] mt-4 z-[50]"
        />
        <p className="text-[14px] w-[360px] max-w-[420px] md:max-w-full sm:w-full md:text-[16px] lg:text-[20px] sm:mt-6 [text-shadow:0px_3px_1.9px_#000]">
          Fierce beings whose goal is to subdue and enslave this world.
          Vengeance warriors who experiment on the intelligent life forms found
          here, leveraging them to become stronger, and wealthier in an attempt
          to win every fight. The Brood show no mercy.
        </p>
      </div>
      <div
        className={`w-full lg:w-[540px] flex flex-col items-end transition-transform duration-[1000ms] ${
          inView ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <img
          alt="intro-kin"
          src="assets/img/landing/kin.png"
          className="w-[360px] md:w-[488px] h-[32px] mt-4 z-[50]"
        />
        <p className="text-[14px] w-[360px] max-w-[420px] md:max-w-full sm:w-full md:text-[16px] lg:text-[20px] sm:mt-6 text-right [text-shadow:0px_3px_1.9px_#000]">
          Victorious heroes guided by confident morale, duty and honor, doing
          all they can to grant peace where the Brood have left only a wake of
          destruction. Join them to save the creatures you will discover here.
        </p>
      </div>
    </div>
  );
};
export default IntroContent;
