import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.css";

const PrivacyPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className={`relative w-full h-fit flex flex-col`}>
      <Header />
      <div
        style={{
          backgroundImage: "url(assets/img/privacy.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="relative flex flex-col w-full h-full items-center pt-4"
      >
        <div className="font-[400] overflow-y-auto leading-5 pr-1 md:pr-2 text-[black] rounded-[2px] flex flex-col text-center md:w-[658px] md:h-[921px] h-[80vh] w-[95%] mt-[22px] md:mt-[55px] text-[14px] md:text-[16px]">
          <div className="flex flex-col text-center w-full py-[26px] px-2 md:px-[24px] h-fit bg-[#D9D9D9]">
            <p className="font-[700]">
              IMU STUDIOS
              <br />
              PRIVACY POLICY
            </p>
            <p className="">
              <br />
              LAST UPDATE OF THIS PRIVACY POLICY - [October 1st, 2024]
              <br />
              <br />
              BEFORE USING OUR SITES, PRODUCTS OR SOFTWARE PLEASE READ THIS
              PRIVACY POLICY CAREFULLY.
              <br />
              <br />
              IMU Studios ("IMU STUDIOS", "IMU" “we”, “us”) understands how
              important your privacy is to you and is committed to protecting
              and safeguarding the Personal Information (as defined below) which
              you have entrusted us with. This policy will govern website and
              text interactions and will be posted upon each of the websites
              owned or controlled by IMU Studios, such as: www.iplaymore.com (a
              “IMU STUDIOS Site”). This policy outlines the manner in which IMU
              STUDIOS (and any IMU STUDIOS Sites) collect, use, disclose and
              manage your Personal Information.
              <br />
              <br />
              IMU STUDIOS manages personal Information in accordance with
              relevant Personal Information protection laws, including as
              applicable the Personal Information Protection Act (British
              Columbia), the Personal Information Protection and Electronic
              Documents Act (Canada), and other relevant laws (collectively
              “Privacy Laws”). This policy explains generally our Personal
              Information practices but in all respects the application of this
              policy and our Personal Information practices will be subject to
              the requirements or provisions of applicable Privacy Laws.
              <br />
              <br />
              When we refer to "Personal Information" within this policy, we
              mean any information about an identifiable individual, such as
              your name, address, email address, birth date, gender, personal
              preferences and opinions, and any other information associated
              with an identifiable individual, except for basic work or business
              contact information as excluded under applicable Privacy Laws.
              <br />
              <br />
              By providing Personal Information to us and by using IMU STUDIOS
              Sites and systems, you agree to the terms of this policy. We
              recommend that you review this policy in its entirety and revisit
              it periodically, as it may change from time to time. All changes
              will be posted on the IMU STUDIOS Sites and will be effective from
              the date of posting.
              <br />
              <br />
              Although you are not required to register to access our Sites, you
              may be asked to provide us with personal information when you
              visit certain sections of our Sites. Your use of our Sites
              signifies your acknowledgement and consent to our Privacy Policy.
              If you do not agree to this Privacy Policy, please do not continue
              to use our Sites. Your continued use of the Sites signifies your
              acceptance of these terms and any changes in effect at the time of
              use.
              <br />
              <br />
            </p>
            <p className="font-[700]">
              Purposes for Collection, Use and Disclosure
            </p>
            <br />
            We may use your Personal Information in any of the following ways:
            <br />
            <br />
            <ul className="text-start list-disc pl-[20px]">
              <li>
                to provide you with services or information that you have
                requested from us, such as email, newsletter services, game and
                event notifications, or contests.
              </li>
              <br />
              <li>
                to administer promotions and contests conducted by us for which
                you have registered to participate;
              </li>
              <br />
              <li>to process customer complaints or suggestions;</li>
              <br />
              <li>to administer and build your personal access account;</li>
              <br />
              <li>as required or authorized by law; and</li>
              <br />
              <li>
                with non-sensitive Personal Information (i.e., name, address,
                phone number and email address) only, to send you information
                about IMU STUDIOS, its products, business and upcoming events or
                those of our business partners, until you advise us that you no
                longer wish to receive such information, in which case we will
                provide you with a clear and simple process for having your name
                removed from such mailings.
              </li>
              <br />
            </ul>
            <br />
            <p className="font-[700]">
              How Does the Company Use the Information Gathered About Online
              Visitors?
            </p>
            <br />
            <p className="">
              Before forwarding us any personal information, please be advised
              that any information gathered on our Sites may be used in the
              aggregate for research and development relating to our Sites
              and/or for future site development and, if you ask us to, to send
              you promotional materials. In particular, we may use information
              gathered about you for the following purposes: to monitor interest
              in our range of products and to assist us to tailor the content of
              our Sites to your needs by collecting information about your
              preferences through tracking of patterns page views on our Sites;
              to create a profile relating to you in order to show you the
              content that might be of interest to you and to display the
              content according to your preferences; and, in circumstances where
              you have indicated that you wish to receive additional
              information, to send you information about us and promotional
              material about our products together with details of any offers we
              may have available from time to time.
            </p>
            <br />
            <p className="font-[700]">Acceptance Of Our Privacy Policy</p>
            <br />
            <p className="">
              By using this Site or any other Company Site, social media or
              interactive ads, you signify your acceptance of our Privacy
              Policy, and you adhere to the terms and conditions posted on the
              Site. By submitting your information, you agree that it will be
              governed by our Privacy Policy.
            </p>
            <br />
            <p className="font-[700]">Consent</p>
            <br />
            <p>
              By providing Personal Information to us and by using IMU STUDIOS
              Sites, you voluntarily consent to the collection, use and
              disclosure of Personal Information as specified in this policy.
              Without limiting the foregoing, we may on occasion ask you to
              consent when we collect, use, or disclose your Personal
              Information in specific circumstances, in which case we may ask
              for your consent in writing although in some circumstances we may
              accept your oral consent. Sometimes, but only regarding
              non-sensitive Personal Information, your consent may be implied.
              For instance, by entering one of our online contests, your consent
              to our use of your Personal Information to administer the contest
              is implied by your completion of the online entry form.
              <br />
              <br />
              Where we want to use your Personal Information for a purpose not
              previously identified to you at the time of collection, we will
              seek your consent prior to our use of such information for this
              new purpose.
              <br />
              <br />
              Should a third party contact you in any way, regarding any of our
              products, services or events that is clearly beyond the control of
              the company and the scope of this privacy policy that covers our
              professional relationship directly.
              <br />
              <br />
              Understand, you may withdraw your consent at any time to any of
              the uses contemplated in this Privacy Policy. Simply, contact a
              representative of our Privacy Office at the following via email at
              privacy (at) imuStudios.com or by mail at: IMU Studios Box 20,
              7528 Upper Balfour Road, Balfour BC, V1L 7E6
              <br />
              <br />
              Please note that withdrawing your consent to some of the
              contemplated uses may render it impossible to continue to provide
              a service which you have currently requested. Also, please note
              that if you withdraw consent, we nonetheless reserve the right to
              retain, collect, use and disclose Personal Information without
              your consent where we are permitted by law to do so.
            </p>
            <br />
            <p className="font-[700]">Information We Collect and Use</p>
            <br />
            We collect your Personal Information in a number of different ways.
            For instance, we collect information from you when you register for
            to test one of our games or sign up for one of our contests or
            promotions, or our electronic newsletters, or complete market
            research studies conducted by us or our partners from time to time.
            We may also collect your Personal Information when you request
            information from us to process a complaint that you have registered
            with us. For the most part, the type of Personal Information we
            collect from you includes the following: name, address, age, phone
            number and other contact information, personal preferences and
            opinions. When you subscribe to a paid service, we will collect
            certain relevant financial information from you which are necessary
            to process any such purchases or administer such accounts.
            <br />
            <br />
            We will limit the amount and type of Personal Information collected
            from you to that which is necessary for the purposes we have
            communicated to you. Further, we will retain your Personal
            Information only for so long as is necessary to fulfill our stated
            purposes or as may be required or permitted by law, after which time
            it will be made anonymous or destroyed unless you further consent to
            its continued retention.
            <br />
            <br />
            <p className="font-[700]">Cookies</p>
            <br />
            When you visit any IMU STUDIOS Sites, we may place a "cookie" on
            your computer. A cookie is a file written to your computer's hard
            drive that records certain technical information about your Internet
            usage, such as your IP address, browser type, or domain name. Also,
            if you submit Personal Information to the IMU STUDIOS Sites (such as
            your name, interests or preferences), we may use cookies to keep
            track of such information so that you will not need to re-enter this
            information during subsequent visits. We use cookies to customize
            delivery of the IMU STUDIOS Sites content. You may set your Internet
            browser preferences to notify you when you receive a cookie or
            decline acceptance of cookies. If you decline acceptance of cookies,
            however, you may experience less than optimal performance of IMU
            STUDIOS Sites.
            <br />
            <br />
            While using IMU STUDIOS Sites, we (ourselves or through certain
            third-party service providers) may collect information about which
            pages you visit, as well as your IP address or domain name. We may
            use this information, for example, to tailor your experience on IMU
            STUDIOS Sites or to assess the amount of traffic to IMU STUDIOS
            Sites.
            <br />
            <br />
            Like most other commercial websites, we may monitor traffic
            patterns, site usage and related site information, and automatically
            collect or compile certain information that is not personally
            identifiable when you access the IMU STUDIOS Sites. Examples of this
            type of information include the aggregate and unique number of
            visitors to the website, the dates and times of visits to the
            website, the domains/countries of visitors, the pages of the website
            visited and the content accessed, the browsers, operating systems,
            search engines and search terms used to visit the website and HTTP
            errors generated. We use this information in order to optimize the
            IMU STUDIOS Sites and to assess usage. We may provide such
            information to our website contractors for these purposes, but this
            information does not include any personally identifiable
            information.
            <br />
            <br />
            The IMU STUDIOS Sites contain links to other third party sites. IMU
            STUDIOS is not responsible for the privacy policies or content on
            such sites.
            <br />
            <br />
            <p className="font-[700]">Disclosure of Personal Information</p>
            <br />
            Except as set out below, we will not sell, rent, transfer, or
            disclose your Personal Information to any third party without your
            consent:
            <br />
            <br />
            <ul className="text-start list-disc pl-[20px]">
              <li>
                We may disclose certain Personal Information to third parties
                solely for the purpose of administering, processing, or
                fulfilling an order or service you have requested on our behalf,
                such as virtual goods, subscriptions, or products to related
                providers, mailing houses, contest administration, etc. We will
                insist that any such third parties maintain the confidentiality
                of the Personal Information we provide them and to implement
                appropriate safeguards to prevent its unauthorized disclosure.
              </li>
              <br />
              <li>
                Where you have given us your explicit consent to do so, we may
                provide non-sensitive Personal Information (such as name,
                address and other contact information) to our corporate sponsors
                and advertisers so that they might send you offers and
                information relating to those products and services that may be
                of interest to you. Again, we will ensure by contractual
                provisions that any such third parties maintain the same high
                standards regarding the protection of your Personal Information
                as set out in this privacy policy and that their use of such
                information be limited only to the purposes which you have
                consented to.
              </li>
              <br />
              <li>
                We will disclose your Personal Information when you have
                consented to the disclosure.
              </li>
              <br />
              <li>
                We may disclose certain Personal Information where it is
                necessary to establish or collect payments as required.
              </li>
              <br />
              <li>
                We may disclose certain Personal Information if we engage a
                third party to provide services to us (like payment processors,
                server references, blockchain account security, computer back-up
                services or archival file storage) and the third party is bound
                by confidentiality obligations.
              </li>
              <br />
              <li>
                We may disclose certain Personal Information when we are
                required or authorized by law to do so.
              </li>
              <br />
              <li>
                We may disclose your Personal Information in connection with a
                corporate reorganization, merger or amalgamation, or pursuant to
                a sale of all or substantially all of the assets of IMU STUDIOS
                or a division of IMU STUDIOS, provided that the uses of such
                Personal Information so disclosed continue to be the uses
                permitted by this privacy policy.
              </li>
            </ul>
            <br />
            <p className="font-[700]">Summarized Data</p>
            <br />
            We may also use your Personal Information and information
            automatically collected through IMU STUDIOS Sites to generate
            information which has been stripped of information potentially
            identifying individuals, and which have been manipulated or combined
            to provide generalized, anonymous information (“Summarized Data”).
            We will use this Summarized Data for internal use and for sharing
            with affiliates, subsidiaries, advertisers and business partners.
            Your identity and personal Information will be kept anonymous in
            Summarized Data. Summarized Data will be used for marketing and
            planning purposes.
            <br />
            <br />
            <p className="font-[700]">Security</p>
            <br />
            The security of your Personal Information is important to us. IMU
            STUDIOS takes technical, contractual, administrative, and physical
            security steps to protect the Personal Information that you provide
            to us. We instruct our employees with access to your Personal
            Information that it is to be used only in adherence with the
            principles set forth in this privacy policy and the laws applicable
            to each specific business.
            <br />
            <br />
            You can help protect the security of your Personal Information as
            well. For instance, never share or give out your user name or
            password, since this is what is used to access your account or
            registration information. Also, remember to log out of your account
            and close your browser window when you finish using any IMU STUDIOS
            Site or surfing the Web, so that other people using the same
            computer won’t have access to your information. This is a vital
            precaution on public or shared computers.
            <br />
            <br />
            <p className="font-[700]">Information Requested from Children</p>
            <br />
            At IMU STUDIOS Sites, we take our responsibilities to our younger
            visitors seriously. In addition to the steps, we take to safeguard
            the privacy and integrity of Personal Information submitted by
            adults, we adhere to the following principles and practices
            regarding Personal Information requested from children under 13
            years old.
            <br />
            <br />
            We will not knowingly collect Personal Information from children
            under 13 (such as full name, home address, email address, phone
            number, etc.). We may use age screening mechanisms to help us
            determine if a user is under the age of 13. If a IMU STUDIOS Site
            determine that the user is under the age of 13, they will be
            directed to content that does not involve the submission of Personal
            Information.
            <br />
            <br />
            From time to time, we may post information on our site about other
            promotions, contests or games that are open to children but that
            require Personal Information to participate. For these contests,
            children will be required to print a paper entry form from the
            website and have their parents sign and mail, scan and email the
            form to us. Children are not permitted to provide Personal
            Information on the website or to enter these contests online.
            <br />
            <br />
            If, at any time, a parent withdraws his or her consent to our use or
            maintenance of Personal Information collected from his or her child,
            we will promptly remove such information from our database.
            <br />
            <br />
            If you have any comments or questions on policies related to
            children's data or about our commitment to protecting your and your
            children's privacy, please contact us at the email address
            identified below.
            <br />
            <br />
            <p className="font-[700]">Access to Your Information</p>
            <br />
            We will give you access to the information we retain about you once
            identity is proven. We will make every reasonable effort to keep
            your information accurate and up-to-date. Upon your request, you
            shall be informed of the existence, use and disclosure of your
            information, except where your access to the information is not
            required or is prohibited by law. You may verify the accuracy and
            completeness of your information, and may request that it be
            amended, if correction is required. Simply send us an email at
            privacy (at) imuStudios.com and we will consider your request.
            <br />
            <br />
            <p className="font-[700]">Miscellaneous</p>
            <br />
            This policy will be governed by the laws of the Province of British
            Columbia and the laws of Canada applicable therein, excluding any
            conflict of laws rules that would apply another body of law. This
            policy constitutes and contains the entire agreement between you and
            IMU STUDIOS with respect to its subject matter and supersedes any
            prior oral or written agreements.
            <br />
            <br />
            If any provision of this policy is held to be illegal or
            unenforceable, that provision will be limited or eliminated to the
            minimum extent necessary so that this policy will otherwise remain
            in full force and effect and enforceable.
            <br />
            <br />
            <p className="font-[700]">Need More Information?</p>
            <br />
            If you have any questions, comments, complaints or suggestions about
            this privacy policy or IMU STUDIOS’ privacy practices, or wish to
            withdraw your consent to our use of your Personal Information for
            any of the purposes contemplated herein you can contact our Privacy
            Office either via email at privacy (at) imuStudios.com or by mail
            at:
            <br />
            <br />
            IMU Studios Privacy Office
            <br />
            Box 20, 7528 Upper Balfour Road, Balfour BC, V1L 7E6
          </div>
        </div>
        <div className=" w-full flex mt-10">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
