import { useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";

const TwichToolPage = () => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const twichToolUrl = "https://www.youtube.com/watch?v=5GcrnPh8yRE";
  const closeModal = () => {
    setSelectedVideo(null);
  };

  return (
    <div className={`w-full h-full flex flex-col bg-[#000000]`}>
      <Header />
      <div className="relative flex flex-col w-full lg:h-fit items-center">
        <img
          alt="twich-tool-thunder-logo"
          src="assets/img/thunder-logo.png"
          className="w-[70%] md:w-[50%] mt-10 block lg:hidden absolute"
        />
        <img
          alt="twich-tool-title"
          src="assets/img/twich-tool/title.png"
          className="md:mt-2 w-[95%] lg:w-[542px] top-[120px] sm:top-[150px] absolute block lg:hidden lg:h-[34px] lg:mt-6 z-[50]"
        />
        <div className="flex flex-col justify-center items-center md:mt-2 w-[90%] md:w-[60%] lg:w-[1141px] absolute top-[180px] sm:top-[210px] lg:hidden lg:h-[12px] mt-1 lg:mt-4 z-[50]">
          <img
            alt="twich-tool-title-line"
            src="assets/img/landing/title-line.png"
            className="w-full"
          />
          <p className="text-[13px] text-center mt-3 w-[90%] z-[200]">
            Our official Influencer Marketing Tool is in progress.
            <br />
            <br />
            this tool is aimed at streamers on the Twitch platform, it will
            allow you to host giveaway events with your community through active
            interaction with it
          </p>
        </div>
        <img
          alt="twich-tool-bg"
          src="assets/img/twich-tool/bg.png"
          className="w-full h-[350px] lg:h-[130vh] lg:-mt-[15vh]"
        />
        <div className="z-[500] flex flex-col absolute top-[290px] lg:top-[25vh] justify-center items-center w-full 2xl:w-[1584px] lg:h-[156px]">
          <img
            alt="twich-tool-thunder-logo"
            src="assets/img/thunder-logo.png"
            className="w-[964px] md:w-[50%] mt-10 hidden lg:block"
          />
          <img
            alt="twich-tool-title"
            src="assets/img/twich-tool/title.png"
            className="w-[80%] lg:w-[72%] hidden lg:block lg:-mt-10 z-[50]"
          />
          <img
            alt="twich-tool-title-line"
            src="assets/img/landing/title-line.png"
            className="w-[90%] lg:w-[50%] hidden lg:block lg:h-[12px] mt-1 lg:-mt-2 z-[50]"
          />
          <p className="hidden lg:flex text-[16px] xl:text-[19px] mt-10 justify-center items-center w-[600px] xl:w-[784px]">
            <div className="flex items-center justify-center w-[353px] h-[199px] md:w-[600px] md:h-[340px] lg:w-[542px] lg:h-[305px]">
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${
                  twichToolUrl.split("v=")[1]
                }`}
                title="Selected Video"
                allowFullScreen
              ></iframe>
            </div>
          </p>
          <div
            style={{
              backgroundImage: "url(assets/img/landing/section-line.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            className="lg:hidden mt-14 z-[100] w-full h-[26px] flex flex-col items-center"
          />
          <div className="lg:hidden -mt-6 flex justify-center">
            <img
              alt="twich-tool-bg-2"
              src="assets/img/landing/landing-bg-2.png"
              className="absolute w-full lg:hidden bg-[#00000067] h-[672px] lg:mt-[80px] opacity-60 pointer-events-none"
            />
            <div className="lg:hidden w-[90%] h-[680px] lg:h-[400px] flex flex-col items-center bg-[#0e151d]">
              <div
                className={`flex-shrink-0 w-[305px] h-[170px] md:w-[542px] md:h-[305px] mt-6`}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${
                    twichToolUrl.split("v=")[1]
                  }`}
                  title="Selected Video"
                  allowFullScreen
                ></iframe>
              </div>
              <p className="text-[14px] text-left mt-3 w-[90%] z-[200]">
                When a viewer is randomly selected, they will have the
                opportunity, by writing in chat during the event, to obtain a
                code to redeem a random gear that will be stored in their
                profile, ready to be used right away in game and viewable in the
                online marketplace.
                <br />
                <br />
                Subscribe to get the latest updates!
              </p>
              <img
                alt="twich-tool-character"
                src="assets/img/twich-tool/character.png"
                className="absolute left-[calc( 50vh - 150px )] bottom-0 sm:-bottom-4 lg:hidden w-[300px] h-[330px]"
              />
            </div>
          </div>
          <div className="absolute lg:flex lg:w-[970px] text-center justify-center items-center top-[460px] hidden">
            Our official Influencer Marketing Tool is in progress.
            <br />
            <br />
            this tool is aimed at streamers on the Twitch platform, it will
            allow you to host giveaway events with your community through active
            interaction with it.
            <br />
            <br />
            When a viewer is randomly selected, they will have the opportunity,
            by writing in chat during the event, to obtain a code to redeem a
            random gear that will be stored in their profile, ready to be used
            right away in game and viewable in the online marketplace.
          </div>
        </div>
        {selectedVideo && (
          <div
            className="z-[2000] w-screen top-0 left-0 h-screen fixed flex justify-center items-center bg-[#0000005a]"
            onClick={closeModal}
          >
            <div className="flex items-center justify-center w-[768px] h-[500px]">
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${
                  selectedVideo.split("v=")[1]
                }`}
                title="Selected Video"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
        <div className="lg:z-[500] mt-[585px] lg:-mt-[140px] 3xl:-mt-[250px] z-[600] w-full h-[200px] md:h-[400px] 3xl:h-[450px] pointer-events-none justify-end flex flex-col items-center">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TwichToolPage;
