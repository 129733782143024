import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.css";

const TermsPage = () => {
  return (
    <div className={`relative w-full h-fit flex flex-col`}>
      <Header />
      <div
        style={{
          backgroundImage: "url(assets/img/privacy.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="relative flex flex-col w-full h-full items-center pt-4"
      >
        <div className="font-[400] overflow-y-auto leading-5 pr-1 md:pr-2 text-[black] rounded-[2px] flex flex-col text-center md:w-[658px] md:h-[921px] h-[80vh] w-[95%] mt-[22px] md:mt-[55px] text-[14px] md:text-[16px]">
          <div className="flex flex-col text-center w-full py-[26px] px-2 md:px-[24px] h-fit bg-[#D9D9D9]">
            <p className="font-bold text-[16px] md:text-[18px]">
              iPlayMore
              <br />
              Terms of Use
            </p>
            <p className="">
              <br />
              Welcome to iPlayMore games. These Terms of Use intend to explain
              our obligations as a service provider and your obligations as a
              customer. Please read them carefully.
              <br />
              <br />
              These Terms are binding on any use of any Account and apply to you
              from the time that iPlayMore provides you with access to any
              Account.
              <br />
              <br />
              By registering to use an Account you acknowledge that you have
              read and understood these Terms and have the authority to act on
              behalf of any person for whom you are using any Account. You are
              deemed to have agreed to these Terms on behalf of any entity for
              whom you use any Account.
              <br />
              <br />
              iPlayMore reserves the right to change these terms at any time,
              effective upon the posting of modified terms to the website. Your
              continued use of any Account after the effective date of the
              revised terms constitutes your acceptance of these terms.
              <br />
              <br />
              iPlayMore will make reasonable efforts to communicate these
              changes to you via email or notification via the Website. You
              should ensure that you have read, understood, and agreed to the
              most recent terms available on the Website.
              <br />
              <br />
              These Terms were last updated on November 29th, 2024
            </p>
            <br />
            <p className="font-bold text-[16px] md:text-[18px]">
              1. Definitions
            </p>
            <br />
            <p className="text-left">
              <p className="font-bold">“Agreement”</p> means these Terms of Use.
              <br />
              <br />
              <p className="font-bold text-[14px] md:text-[16px]">
                “Confidential Information”
              </p>
              means all information of a secret or proprietary nature disclosed
              to you by iPlayMore to the business of iPlayMore, IMU Studios,
              GameOnAImLive, and/or THUNDER© including but not limited to any
              Account use and any proprietary rights and information contained
              therein.
              <br />
              <br />
              <p className="font-bold text-[14px] md:text-[16px]">
                “Documentation”
              </p>
              means the documentation provided by iPlayMore to customers
              relating to any Licensed Software including any documentation
              related to integrations between the Licensed Software and your
              Licensee Content.
              <br />
              <br />
              <p className="font-bold text-[14px] md:text-[16px]">“End User”</p>
              means any individual or entity that: (a) accesses or uses Licensee
              Content; or (b) otherwise accesses or uses any Account under your
              account.
              <br />
              <br />
              <p className="font-bold text-[14px] md:text-[16px]">
                “Licensee Content”
              </p>
              means any content that is being integrated and made available to
              the general public.
              <br />
              <br />
              <p className="font-bold text-[14px] md:text-[16px]">“Account”</p>
              means the provision of the functionality of the Licensed Software
              to you and any Account provided by iPlayMore to you in connection
              with the Licensed Software.
              <br />
              <br />
              <p className="font-bold text-[14px] md:text-[16px]">“Website”</p>
              means the internet site at the domain www.iPlayMore.com or any
              other site operated by iPlayMore or IMU Studios.
              <br />
              <br />
              <p className="font-bold text-[14px] md:text-[16px]">“You”</p>
              means the person who registers to use any Account, and, where the
              context permits, includes any entity on whose behalf that person
              registers to use any Account (irrespective of whether a personal
              or business email address is used to complete the registration).
              “your” has a corresponding meaning.
              <br />
              <br />
              <p className="font-bold text-[16px] md:text-[18px] text-center">
                2. Grant of Licence
              </p>
              <br />
              (1) iPlayMore grants you the right to access and use the Licensed
              Software solely for your internal purposes for the duration of
              this Agreement. This right is non-exclusive, non-transferable, and
              limited by and subject to this Agreement.
              <br />
              <br />
              (2) you may not: (a) modify, adapt, decompile, disassemble, or
              reverse engineer any component of the Licensed Software; (b)
              create derivative works based on any component of the Licensed
              Software; (c) allow any third party to use or have access to any
              component of the Licensed Software or Documentation (whether via
              sublicense, lease, resale or otherwise); or (d) access or use any
              Account in a way intended to avoid incurring fees or exceeding use
              limits. Any copy of any component of the Licensed Software or
              Documentation made by you, including any partial copy, is the
              property of iPlayMore and/or IMU Studios. You will include on each
              such copy all copyright, trademarks, and other proprietary rights
              notices included by iPlayMore on the originals.
              <br />
              <br />
              <p className="font-bold text-[16px] md:text-[18px] text-center">
                3. Proprietary Rights
              </p>
              <br />
              (1) you acknowledge and agree that: (a) the Licensed Software and
              Documentation are the property of iPlayMore or its licensors and
              not yours, and (b) you will use the Licensed Software and
              Documentation only under the terms and conditions described
              herein.
              <br />
              <br />
              (2) you acknowledge that the Licensed Software and Documentation
              contain valuable proprietary information and trade secrets of
              iPlayMore and/or IMU Studios that you shall take reasonable
              measures to protect the secrecy of, and avoid disclosure and
              unauthorized use of, the Licensed Software. Without limiting the
              foregoing you agree: (a) to take concerning the Licensed Software
              and Documentation at least those measures that you take to protect
              your confidential information; and (b) that the Licensed Software
              and Documentation may not be disclosed, reproduced, summarized,
              distributed or used except as necessary to exercise the license
              granted hereunder.
              <br />
              <br />
              (3) you are not obligated to provide feedback or suggest
              improvements to the Service to us (“Feedback”), however, if you do
              provide Feedback to us or our affiliates, we may use that Feedback
              without restriction.
              <br />
              <br />
              <p className="font-bold text-[16px] md:text-[18px] text-center">
                4. Privacy Policy
              </p>
              <br />
              (1) you agree to the terms of iPlayMore’s Privacy Policy, which is
              available on the Website at{" "}
              <a href="/privacy" target="_blank">
                https://www.iplaymore.com/privacy
              </a>
              <br />
              <br />
              <p className="font-bold text-[16px] md:text-[18px] text-center">
                5. Term and termination
              </p>
              <br />
              (1) The license granted under this Agreement for the Licensed
              Software enters into force starting from the moment of
              registration and it shall be perpetual unless terminated.
              <br />
              <br />
              (2) iPlayMore, at its sole discretion, may suspend or terminate
              this Agreement with immediate effect if:
              <br />
              <br />
              • iPlayMore suspects that you are endangering the Licensed
              Software, the Licensee Content of any other customer, or your use
              of any Account is fraudulent or could subject us, our affiliates,
              or any third party to liability;
              <br />
              <br />
              • you commit any material breach of your obligations under this
              Agreement which, in the case of a breach capable of remedy, is not
              remedied within fourteen (14) days of the date of service of a
              written notice specifying the breach and requiring it to be
              remedied;
              <br />
              <br />
              • you hold any meeting with or propose to enter into or have
              proposed to it any arrangement or composition with your creditors
              (including any voluntary arrangement as described in the
              Insolvency Act 1986), you have a receiver, administrator or other
              encumbrancer take possession of or appointed over or have any
              distress, execution or other process levied or enforced (and not
              discharged within seven (7) days) upon the whole or substantially
              all of your assets, or you cease to carry on business or become
              unable to pay your debts within the meaning of Section 123 of the
              Insolvency Act 1986;
              <br />
              <br />
              • you have or may become incapable of performing your obligations
              under this Agreement;
              <br />
              <br />
              • Our rights to the software or other technology we use to provide
              the Licensed Software expires, terminates, or requires us to
              change the Licensed Software; or
              <br />
              <br />
              • Such action is required to comply with the law or a request of a
              governmental entity.
              <br />
              <br />
              (3) This Agreement, and the license granted in this Agreement, may
              be terminated by iPlayMore after ten (10) days written notice upon
              the occurrence of one or more of the following:
              <br />
              <br />
              • upon your attempt to reverse engineer the Licensed Software or
              in any other way to use of the Licensed Software in a manner
              inconsistent with Section 2 above.
              <br />
              <br />
              • upon any other breach by you of your material obligations under
              this Agreement or any support agreement with iPlayMore relating to
              the Licensed Software.
              <br />
              <br />
              (4) This Agreement, and the licences granted in this Agreement,
              may be terminated by iPlayMore for any reason after thirty (30)
              days’ written notice.
              <br />
              <br />
              (5) Should this Agreement be terminated, you agree to return or
              certify to the destruction of all copies of the Licensed Software
              (including the SDKs) and Documentation in your possession, and all
              amounts owed by you under this Agreement shall be immediately due
              and payable. To remove all doubt, all rights and licenses granted
              to you hereunder shall immediately terminate upon any termination
              or expiration of this Agreement) below.
              <br />
              <br />
              (6) The terms set out in sections 3, 9, 10(3) (final sentence)
              10(4), 11(5), and 13 survive termination, cancellation, or
              expiration of this Agreement.
              <br />
              <br />
              <p className="font-bold text-[16px] md:text-[18px] text-center">
                6. Confidentiality
              </p>
              <br />
              (1) you undertake, for the duration of this agreement and
              thereafter, to keep secret any Confidential Information received
              or which could be gained in the process of performing the
              Agreement and not to use it for any purpose other than the
              performance of your obligations under this Agreement.
              <br />
              <br />
              (2) your obligations of confidentiality and non-use shall not
              apply to any information or data which:
              <br />
              • becomes public knowledge otherwise than as a result of any act
              or default by you;
              <br />
              • is public knowledge at the time of its receipt by you;
              <br />
              • is required to be disclosed by law to any governmental or other
              authority or regulatory body provided that where practicable the
              requirement to disclose is first notified to iPlayMore so that
              iPlayMore may first exhaust any rights of appeal it may have
              against such requirement.
              <br />
              <br />
              (3) you may disclose Confidential Information to your:
              <br />
              • directors or employees or any members of your group that need to
              have access to it for the Agreement; and/or
              <br />
              • professional advisers subject to appropriate conditions of
              confidentiality.
              <br />
              <br />
              (4) you shall ensure that any of your directors, employees, and
              advisers shall comply with the obligations of confidentiality
              contained in this clause.
              <br />
              <br />
              <p className="font-bold text-[16px] md:text-[18px] text-center">
                7. Warranty and liability
              </p>
              <br />
              (1) iPlayMore warrants to and undertakes with you that:
              <br />
              • iPlayMore will use its reasonable efforts to provide any Account
              with reasonable care and skill, following the terms of this
              Agreement; and
              <br />
              • iPlayMore has full right of power and authority to enter into
              this Agreement.
              <br />
              <br />
              (2) EXCEPT FOR THE EXPRESS WARRANTIES OUTLINED IN THIS CLAUSE, any
              Account ARE PROVIDED ON AN “AS IS” BASIS, AND your USE OF any
              Account IS AT your OWN RISK. iPlayMore DOES NOT MAKE, AND HEREBY
              DISCLAIMS, ANY AND ALL OTHER EXPRESS AND/OR IMPLIED WARRANTIES,
              STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, WARRANTIES
              OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              NON-INFRINGEMENT, AND ANY WARRANTIES ARISING FROM A COURSE OF
              DEALING, USAGE, OR TRADE PRACTICE. iPlayMore DOES NOT WARRANT THAT
              any Account WILL BE UNINTERRUPTED, ERROR-FREE, OR COMPLETELY
              SECURE.
              <br />
              <br />
              (3) iPlayMore DOES NOT AND CANNOT CONTROL THE FLOW OF DATA TO OR
              FROM ITS NETWORK AND OTHER PORTIONS OF THE INTERNET. SUCH FLOW
              DEPENDS IN LARGE PART ON THE PERFORMANCE OF INTERNET Account
              PROVIDED OR CONTROLLED BY THIRD PARTIES. AT TIMES, ACTIONS OR
              OMISSIONS OF SUCH THIRD PARTIES CAN IMPAIR OR DISRUPT CONNECTIONS
              TO THE INTERNET (OR PORTIONS THEREOF). ALTHOUGH iPlayMore WILL USE
              COMMERCIALLY REASONABLE EFFORTS TO TAKE ALL ACTIONS IT DEEMS
              APPROPRIATE TO REMEDY AND AVOID SUCH EVENTS, iPlayMore CANNOT
              GUARANTEE THAT SUCH EVENTS WILL NOT OCCUR. ACCORDINGLY, iPlayMore
              DISCLAIMS ANY AND ALL LIABILITY RESULTING FROM OR RELATED TO SUCH
              EVENTS.
              <p className="font-bold text-[16px] md:text-[18px] text-center">
                8. Security
              </p>
              <br />
              (1) you are responsible for properly configuring and using the
              Licensed Software and otherwise taking reasonable steps to prevent
              security breaches and secure and backup Licensee Content.
              <br />
              <br />
              (2) you are responsible for maintaining the confidentiality of any
              passwords that are required to access the Licensed Software and
              are solely responsible for any damage caused by any such
              unauthorized access.
              <br />
              <br />
              <p className="font-bold text-[16px] md:text-[18px] text-center">
                9. General
              </p>
              <br />
              (1) All notices under this Agreement to you shall be sent to the
              email addresses specified at registration or provided by you on
              the Website. All notices under this Agreement to us must be via
              email to legal@iPlayMore.com, or by personal delivery, overnight
              courier, or certified mail to iPlayMore, Box 20, 7528 Upper
              Balfour Road, Balfour BC V1L 7E6; notices provided via registered
              or certified mail will be effective five business days after they
              are sent.
              <br />
              <br />
              (2) This Agreement shall be governed by the laws of Canada,
              without reference to conflicts of law principles. Both parties
              expressly agree to submit to the exclusive jurisdiction and venue
              of British Columbia, as to any legal action brought to enforce,
              interpret, or receive damages for a breach of this Agreement. All
              communications and notices made or given under this Agreement must
              be in the English language.
              <br />
              <br />
              (3) This Agreement is subject to all present and future
              regulations and restrictions of the government and agencies of all
              applicable government entities and each party will comply with all
              applicable import, re-import, sanctions, anti-boycott, export, and
              re-export control laws and regulations. you agree that you will
              not ship or divert the Licensed Software or the Documentation with
              respect thereto for use in any country or countries in
              contravention of the laws and regulations of such government or
              agencies or knowingly cause or permit such shipping or diversion
              without the prior written approval of such government or agencies
              you represent and warrant that you and your financial
              institutions, or any party that owns or controls you or your
              financial institutions, are not subject to sanctions or otherwise
              designated on any list of prohibited or restricted parties. You
              are solely responsible for compliance related to how you choose to
              use any Account, including your transfer and processing of
              Licensee Content and any End User data, and the provision of
              Licensee Content to End Users.
              <br />
              <br />
              (4) If any provision in this Agreement is found or held to be
              invalid or unenforceable, then the meaning of such provision shall
              be construed, to the extent feasible, to render the provision
              enforceable, and if no feasible interpretation would save such
              provision, it shall be severed from the remainder of this
              Agreement which shall remain in full force and effect.
              <br />
              <br />
              (5) A waiver of a breach or default under this Agreement shall not
              be a waiver of any other breach or default. Failure of either
              party to enforce compliance with any term or condition of this
              Agreement shall not constitute a waiver of such term or condition
              unless accompanied by a clear written statement that such term or
              condition is waived.
              <br />
              <br />
              (6) This Agreement may not be assigned by you, including an
              assignment by operation of law, without the prior written consent
              of iPlayMore, which consent shall not be unreasonably withheld.
              <br />
              <br />
              (7) neither party will be responsible for any failure to perform
              due to causes beyond its reasonable control (each a “Force
              Majeure”), including, but not limited to, acts of God, war, riot,
              embargoes, acts of civil or military authorities, denial of or
              delays in the processing of export license applications, fire,
              floods, earthquakes, accidents, strikes, or fuel crises.
              <br />
              <br />
              (8) This Agreement contains the entire understanding of the
              parties concerning the matters contained herein. There are no
              promises, covenants, or undertakings other than those expressly
              set forth herein, and any other terms and conditions are rejected
              regardless of content, timing, or method of communication. Any
              deviations from or additions to the terms of this Agreement must
              be in writing and will not be valid unless confirmed in writing by
              duly authorized officers of you and iPlayMore.
              <br />
              <br />
              (9) Unless explicitly requested otherwise in writing, you
              authorize iPlayMore to include your name in iPlayMore customer
              reference lists and publicly distributed materials, such as
              brochures, commercial presentations, advertising, conference
              proceedings, press releases, etc., and to make use of such
              materials for external communication purposes without any
              restrictions.
              <br />
              <br />
              (10) The parties are independent contractors, and this Agreement
              may not be construed to create a partnership, joint venture,
              agency, or employment relationship. This Agreement does not create
              any third-party beneficiary rights in any individual or entity
              that is not a party to this Agreement. Each party reserves the
              right to (a) develop or have developed for its products, Accounts,
              concepts, systems, or techniques that are similar to or compete
              with the products, Accounts, concepts, systems, or techniques
              developed or contemplated by the other party, and (b) assist third
              parties who may offer products or Account which compete with the
              other party’s products or Account.
              <br />
              <br />
            </p>
          </div>
        </div>
        <div className=" w-full flex mt-10">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
