const LogItem = () => {

    return (
        <>
            <div className="bg-[#0C0C0C] z-[100] w-[90%] md:w-[700px] h-fit flex flex-col pb-4 md:pb-8 px-3 md:px-7 py-2 md:py-7 mb-11 shadow-super-strong">
                <p className="text-third-hover text-[24px] md:text-[32px]">
                    Current state - 09/05/2024
                </p>
                <p className="text-[16px] md:text-[22px] mt-4">
                    Welcome to THUNDER©
                    <br />
                    <br />
                    iPlayMore is proud to present project THUNDER© to you and are
                    very excited to share our epic journey with you as we are building
                    our innovative game for you to enjoy in the near future.
                    We look forward to having you join us on this adventure as we
                    share our updates with you during the development.
                    <br />
                    <br />
                    -iPlayMore Team
                </p>
            </div>
            <div className="bg-[#0C0C0C] z-[100] w-[90%] md:w-[700px] h-fit flex flex-col pb-4 md:pb-8 px-3 md:px-7 py-2 md:py-7 shadow-super-strong mb-[100px] md:mb-[60px]">
                <p className="text-third-hover text-[24px] md:text-[32px]">
                    Current state - 08/07/2024
                </p>
                <p className="text-[16px] md:text-[22px] mt-4">
                    Our team is working hard to develop the Prologue
                    of the game, due to this we are unable to share
                    more information. (Top Secret!)
                    Stay tuned to receive the latest updates and developments, we
                    are working hard to deliver something incredible for you all
                    to enjoy!
                    <br />
                    <br />
                    -iPlayMore Team
                </p>
            </div>
        </>
    )
}
export default LogItem;