import React from "react";
import { useEffect, useRef } from "react";
import { toggleRecapachaModal } from "../../state/modal";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import ReCAPTCHA from "react-google-recaptcha";

const RecapachaModal = () => {
    const recaptcha = useRef(null);
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.modalState.recapachaModal);
    const siteKey = process.env.REACT_APP_SITE_KEY || "";
    const handleRecaptchaChange = (value: any) => {
        if (value) {
            setTimeout(() => {
                dispatch(toggleRecapachaModal(false));
            }, 2000);
        }
    };
    return (
        <div
            className={`${isOpen ? 'block' : 'hidden'
                } fixed z-[5001] flex w-full h-full justify-center items-center bg-[black] bg-opacity-90`}
        >
            <div className="grid w-[342px] h-[147px] bg-[white] text-[black]">
                <p className="flex items-center pl-4 text-sm text-black">Please check the box below to proceed.</p>
                <div className="flex justify-center">
                    <ReCAPTCHA
                        ref={recaptcha}
                        sitekey={siteKey}
                        onChange={handleRecaptchaChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default RecapachaModal;