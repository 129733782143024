import { useInView } from "react-intersection-observer";
import Craft from "../../components/landing/craft";
import Footer from "../../components/footer";

const FourthSection = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  return (
    <div className="relative flex flex-col w-full mt-16 sm:mt-18 xl:mt-32">
      <div
        style={{
          backgroundImage: "url(assets/img/landing/section-line.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="z-[100] hidden w-full h-[26px] -mt-5 sm:flex flex-col items-center rotate-180"
      >
        &nbsp;
      </div>
      <div className="sm:hidden bg-[black] h-16 z-[200] w-full flex flex-col items-center justify-between absolute -top-16">
        <img
          alt="landing-section-line-mb"
          src="assets/img/landing/section-line-mb.png"
          className="w-full md:w-[252px] h-[12px] z-[50] -mt-[1px]"
        />
        <p className="text-[20px] text-third-hover drop-shadow-[0_9px_5.5px_#000]">
          Upgrade & Explore
        </p>
        <img
          alt="landing-section-line-mb-2"
          src="assets/img/landing/section-line-mb.png"
          className="w-full md:w-[252px] h-[12px] z-[50] rotate-180 -mb-[1px]"
        />
      </div>
      <div
        style={{
          backgroundImage:
            "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(assets/img/landing/fourth-bg.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          transition: "opacity 4s ease-in-out",
        }}
        className="hidden relative lg:flex flex-col items-center justify-center w-full h-[346px]"
      >
        <Craft />
      </div>

      <div className="lg:hidden relative flex flex-col items-center justify-between w-full h-fit">
        <div
          style={{
            backgroundImage: "url(assets/img/landing/fourth-bg.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            transition: "opacity 4s ease-in-out",
          }}
          className="w-full h-fit -mt-3 z-[49] pt-12 pb-6 sm:pb-12"
        >
          <div className="flex justify-center items-center w-full relative">
            <img
              alt="landing-craft-detect"
              src="assets/img/landing/craft-detect.png"
              className={`absolute w-[100px] -scale-y-100 bottom-0 right-12 z-[50]`}
            />
            <img
              alt="landing-craft-detect-2"
              src="assets/img/landing/craft-detect.png"
              className={`absolute w-[100px] -scale-x-100 top-0 left-12 z-[50]`}
            />
            <img
              alt="landing-craft-item"
              src="assets/img/landing/craft-item.png"
              className={`w-[70%] z-[50]`}
            />
          </div>
          <Craft />
        </div>
      </div>
      <div
        style={{
          backgroundImage: "url(assets/img/landing/section-line.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="z-[100] hidden w-full h-[26px] sm:flex flex-col items-center"
      ></div>
    </div>
  );
};
export default FourthSection;
