import { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";

const GamePage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`w-full h-full flex flex-col mt-16 md:mt-24`}>
      <div className="relative flex flex-col w-full lg:h-fit items-center">
        <div className="w-full h-fit items-center flex flex-col">
          <div className="w-full h-fit flex flex-col z-[1000] items-center text-[white] text-[14px] md:text-[20px] md:leading-7 2xl:text-[16px] 2xl:leading-[22px]">
            <div className="flex justify-center 2xl:flex-row flex-col gap-5 -mt-2 lg:-mt-10">
              <div className="h-full hidden md:flex w-fit flex-col justify-start items-center gap-5">
                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(assets/img/game/1.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  className="flex flex-col 2xl:w-[877px] 2xl:h-[440px] w-full h-fit py-3 md:py-12 2xl:py-5 justify-center items-start px-3 md:px-10 bg-[black]"
                >
                  <p className="text-third-hover text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                    Our THUNDER
                  </p>
                  <p className="2xl:w-[281px] w-[80%]">
                    <br />
                    Have you ever dreamed of joining the battle in a world where
                    two enemy factions are fighting to gain dominance over a
                    shared territory?{" "}
                  </p>
                  <p className="2xl:w-[281px] w-[80%]">
                    <br />
                    To participate in a team-based, match-driven FPS shooter
                    where you can take a third-person RPG break to explore a
                    massive open world full of quests and stories set in a
                    universe rich with captivating lore?
                  </p>
                  <p className="w-[90%]">
                    <br />A game where you can earn money by buying, selling,
                    and renting unique game gear, all while defending your
                    faction’s cause? All of these dreams have been shared by our
                    team at IMU Studios, dreams that drove us to create THUNDER©
                    for you. us to create THUNDER© for you.
                  </p>
                </div>
                <div className="2xl:w-[877px] 2xl:h-[300px] w-full h-fit py-5 md:py-20 2xl:py-5 flex justify-end bg-[#000] relative">
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(assets/img/game/2.png)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className="flex flex-col 2xl:w-[805px] 2xl:h-[246px] w-full h-[100px] md:h-[246px] justify-center items-start px-3 md:px-10"
                  ></div>
                  <div className="flex flex-col absolute 2xl:w-[877px] w-[80%] h-fit 2xl:h-[286px] top-6 left-3 md:left-10">
                    <p className="text-third-hover text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                      The Vision
                    </p>
                    <p className="w-[50%] md:w-[414px]">
                      <br />
                      Our work reflects the love we have for fast-paced action,
                      original game design, and true player freedom. From
                      conception to the ever-expanding universe, our
                      character-driven multiplayer gameplay is designed to offer
                      players countless.
                    </p>
                    <p className="w-[90%] hidden md:block md:w-[632px]">
                      <br />
                      ways to immerse themselves in the open world we’ve
                      created.  In this world, you are not just simple players
                      but true actors on Nebulous Prime, a planet rich in
                      mysteries and cosmic dangers. 
                    </p>
                  </div>
                </div>
              </div>
              <div className="h-full flex flex-col justify-start items-center sm:gap-5 relative">
                <div className="sm:hidden bg-[black] h-16 z-[200] w-full flex flex-col items-center justify-between absolute -top-16">
                  <img
                    alt="landing-section-line-mb"
                    src="assets/img/landing/section-line-mb.png"
                    className="w-full md:w-[252px] h-[12px] z-[50] -mt-[1px]"
                  />
                  <p className="text-[20px] text-third-hover drop-shadow-[0_9px_5.5px_#000]">
                    Our Origins
                  </p>
                  <img
                    alt="landing-section-line-mb-2"
                    src="assets/img/landing/section-line-mb.png"
                    className="w-full md:w-[252px] h-[12px] z-[50] rotate-180 -mb-[1px]"
                  />
                </div>
                <div
                  style={{
                    backgroundImage:
                      "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(assets/img/game/3.png)",
                    backgroundSize: "cover",
                    backgroundPosition: "left",
                  }}
                  className="flex flex-col w-full 2xl:w-[595px] h-fit py-2 sm:py-6 2xl:h-[364px] justify-start items-end pr-4 md:pr-10 2xl:pr-4"
                >
                  <p className="hidden sm:block text-third-hover text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                    Our Origins
                  </p>
                  <p className="w-[70%] md:w-[400px] lg:w-[40%] 2xl:w-[314px] text-end">
                    <br />
                    Our game was born in 2007 when IMU Studios developed a
                    prototype project called "Prince of THUNDER."  The 2008
                    economic crisis eventually slowed this initial step into the
                    gaming market.
                  </p>
                  <p className="w-[80%] md:w-[400px] lg:w-[40%] 2xl:w-[314px] text-end block 2xl:hidden">
                    <br />
                    However, during live gaming event tours, our captivating
                    concepts inspired people and influenced new designs and
                    creations, keeping our game alive. Video game technology
                    continues to soar to new heights, and THUNDER© has become
                    better than ever.
                  </p>
                  <Link to="/iplaymore">
                    <div
                      style={{
                        backgroundImage: "url(assets/img/landing/sub-btn.png)",
                        backgroundSize: "cover",
                      }}
                      className="cursor-pointer text-[14px] md:text-[20px] hover:text-secondary-hover font-[400] flex items-center justify-center w-[150px] h-[66px] mt-3 md:mt-10 z-[50]"
                    >
                      Know more
                    </div>
                  </Link>
                </div>
                <div className="w-full 2xl:w-[595px] overflow-x-hidden overflow-y-hidden h-[440px] md:h-[376px] flex justify-end bg-[#000] relative">
                  <div className="sm:hidden bg-[black] h-16 z-[200] w-full flex flex-col items-center justify-between absolute top-0">
                    <img
                      alt="landing-section-line-mb"
                      src="assets/img/landing/section-line-mb.png"
                      className="w-full md:w-[252px] h-[12px] z-[50] -mt-[1px]"
                    />
                    <p className="text-[20px] text-third-hover drop-shadow-[0_9px_5.5px_#000]">
                      Gear up!
                    </p>
                    <img
                      alt="landing-section-line-mb-2"
                      src="assets/img/landing/section-line-mb.png"
                      className="w-full md:w-[252px] h-[12px] z-[50] rotate-180 -mb-[1px]"
                    />
                  </div>
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(assets/img/game/4.png)",
                      backgroundSize: "cover",
                      backgroundPosition: "right",
                    }}
                    className="flex flex-col absolute -right-20 bottom-0 sm:top-0 w-[80%] h-[80%] sm:w-[90%] 2xl:w-[595px] py-6 sm:h-full md:h-full justify-center items-start"
                  ></div>
                  <div className="flex flex-col absolute md:w-[595px] h-fit 2xl:h-[286px] top-6 left-0 pl-6 sm:pl-10">
                    <p className="text-third-hover hidden sm:block text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                      Gear up!
                    </p>
                    <p className="w-[80%] sm:w-[400px] md:w-[90%] 2xl:w-[274px] pt-12 sm:pt-0">
                      <br />
                      With 18 unique playable characters to choose from, our
                      gameplay loop revolves around a fluid combat system as
                      players dive into any of our diverse outdoor arenas to
                      control their territory. 
                    </p>
                    <p className="w-[50%] sm:w-[200px] md:w-[90%] 2xl:w-[274px]">
                      <br />
                      In battle, players can use a combination of tools at their
                      disposal,  such as our extensive array of unique combat
                      devices or our elemental-powered scepters to dominate
                      their foes. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full 2xl:flex-row h-fit flex-col justify-center pt-5 relative sm:gap-5">
              <div className="sm:hidden bg-[black] h-16 z-[200] w-full flex flex-col items-center justify-between absolute top-0">
                <img
                  alt="landing-section-line-mb"
                  src="assets/img/landing/section-line-mb.png"
                  className="w-full md:w-[252px] h-[12px] z-[50] -mt-[1px]"
                />
                <p className="text-[20px] text-third-hover drop-shadow-[0_9px_5.5px_#000]">
                  The multi-experience
                </p>
                <img
                  alt="landing-section-line-mb-2"
                  src="assets/img/landing/section-line-mb.png"
                  className="w-full md:w-[252px] h-[12px] z-[50] rotate-180 -mb-[1px]"
                />
              </div>
              <div className="h-full flex flex-col justify-start items-center gap-5">
                <div className="w-full 2xl:w-[530px] h-fit 2xl:h-[400px] flex justify-end bg-[#000] relative">
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(assets/img/game/5.png)",
                      backgroundSize: "cover",
                      backgroundPosition: "right",
                    }}
                    className="flex flex-col w-[90%] 2xl:w-[540px] h-[440px] sm:h-[400px] md:h-[580px] 2xl:h-[380px] mt-0 md:mt-4 justify-center items-start px-6"
                  ></div>
                  <div className="flex flex-col absolute w-[100%] 2xl:w-[575px] h-fit 2xl:h-[286px] top-6 left-0 pl-6 sm:pl-10">
                    <p className="hidden sm:block text-third-hover text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                      The multi-experience
                    </p>
                    <p className="w-[90%] md:w-[495px] mt-3">
                      <br />
                      1. Throne Wars = Fast-paced intense outdoor arena
                      gameplay.
                      <br />
                      <br />
                      2. Bonfire Stories = Relaxing and immersive in-game
                      experiences
                      <br />
                      <br />
                      3. Quests = Become engaged in the exciting events
                      occurring all over Nebulous Prime, a planet teeming with
                      exotic flora, fauna, and a vast cast of interesting
                      characters.
                      <br />
                      <br />
                      4. Crafting = Collect raw ingredients and use them to
                      craft valuable items needed by other players.
                      <br />
                      <br />
                      5. Marketplace = Sell and rent your game gear for a
                      growing real-world profit.
                    </p>
                  </div>
                </div>
              </div>
              <div className="h-full flex flex-col justify-start items-center gap-5 relative">
                <div className="sm:hidden bg-[black] h-16 z-[200] w-full flex flex-col items-center justify-between absolute top-0">
                  <img
                    alt="landing-section-line-mb"
                    src="assets/img/landing/section-line-mb.png"
                    className="w-full md:w-[252px] h-[12px] z-[50] -mt-[1px]"
                  />
                  <p className="text-[20px] text-third-hover drop-shadow-[0_9px_5.5px_#000]">
                    Better than Free to Play
                  </p>
                  <img
                    alt="landing-section-line-mb-2"
                    src="assets/img/landing/section-line-mb.png"
                    className="w-full md:w-[252px] h-[12px] z-[50] rotate-180 -mb-[1px]"
                  />
                </div>
                <div className="w-full 2xl:w-[942px] h-fit 2xl:h-[400px] flex justify-end bg-[#000]">
                  <div
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(assets/img/game/8.png)",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className="flex flex-col w-full h-full mt-0 justify-center items-start px-6"
                  >
                    <div className="flex flex-col items-center w-full py-12 2xl:py-0">
                      <p className="hidden sm:block text-third-hover text-[18px] md:text-[24px] lg:text-[34px] font-[400]">
                        Better than Free to Play
                      </p>
                      <p className="w-[90%] mt-3 text-center">
                        <br />
                        By including game gear that increases in value for
                        players in real life, THUNDER© stands out from other
                        games by pioneering our exclusive "Play, for Profit"
                        gameplay model. 
                        <br />
                        <br />
                        Here’s how it works: owners’ digital gear resides in the
                        common marketplace, allowing other players to rent, use,
                        and level up the game gear that you own while you’re not
                        using it.
                        <br />
                        <br />
                        This enables players to earn money by increasing the
                        value of your owned items as they level up.  This
                        innovative design balances fast-paced competitiveness on
                        the battlefield with growing collaboration outside of
                        it.  A whole new world of possibilities, specifically
                        designed to make players the heartbeat and soul, to
                        become the most valuable assets of our community.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="hidden sm:block text-third-hover mb-[15vh] text-[12px] md:text-[24px] text-center mt-6 w-[90%]">
              <br />
              One company, one dream, one goal: making games all in, for you!
            </p>
            <div className="sm:hidden bg-[black] h-20 z-[200] w-full flex flex-col items-center justify-start mb-[95px]">
              <img
                alt="landing-section-line-mb"
                src="assets/img/landing/section-line-mb.png"
                className="w-full md:w-[252px] h-[12px] z-[50] -mt-[1px]"
              />
              <p className="text-[15px] text-third-hover drop-shadow-[0_9px_5.5px_#000] text-center mt-2 w-[80%]">
                One company, one dream, one goal: making games that are all in,
                for you! 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="z-[1400] -mt-[335px] lg:-mt-[40vh] w-full h-[405px] md:h-[600px] pointer-events-none justify-end flex flex-col items-center">
        <Footer />
      </div>
    </div>
  );
};

export default GamePage;
