import { configureStore } from '@reduxjs/toolkit';
import modalSlice from './modal';
import authSlice from './auth';

export const store = configureStore({
  reducer: {
    modalState: modalSlice,
    authSlice: authSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
