interface InputProps {
  type: string;
  placeholder: string;
  value?: string;
  onChange: (value: string) => void;
  className?: string;
}

const Input = ({ type, placeholder, value, onChange, className }: InputProps) => (
  <input
    type={type}
    value={value}
    onChange={(e) => onChange(e.target.value)}
    placeholder={placeholder}
    className={`w-full h-[38px] md:h-[6vh] px-6 bg-[#131313] rounded-[16px] border-[0.23vh] border-fourth-hover text-white placeholder-[#ffffff33] focus:border-[#00A3FF] focus:outline-none focus:border-transparent ${className || ''}`}
  />
);

export default Input;
